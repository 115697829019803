
export function ShowMsg(form, type, msg) {
    const alert = $(
      '<div class="alert alert-' +
        type +
        ' alert-dismissible" role="alert"><div class="alert-text">' +
        msg +
        '</div><div class="alert-close" ><i class="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i></div></div>'
    );
  
    form.find('.alert').remove();
    alert.prependTo(form);
    alert.find('span').html(msg);
  }
  
  export const RemoveMsg = (form) => form.find('.alert').remove();
  
  export function StartProcessing(btn) {
    btn
      .addClass(
        `kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light ${btn[0].id == 'kt_login_signin_google' && ' kt-spinner--primary' }`
      )
      .attr('disabled', true);
  }
  
  export function StopProcessing(btn) {
    btn
      .removeClass(
        `kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light ${btn[0].id == 'kt_login_signin_google' && ' kt-spinner--primary' }`
      )
      .attr('disabled', false);
  }

export function SanitizeUrl (input) {
  return input.replace(":", "").replace("&", "-and-").replace(/ /g, "-").replace("/", "").replace(",", "").toLowerCase().replace(/<br\/>/g, "_").replace(/<br>/g, "_").replace("'", "").replace(/"/g, "").toLowerCase();
}

  export const getDeviceDetails = async(deviceData) => {

    if(deviceData && (deviceData.isMobile || deviceData.isTablet)){
      return `${deviceData.os} ${deviceData.osVersion}`
    }else{
      return`${deviceData.osName} ${deviceData.osVersion}-${deviceData.browserName}`
    }
  
  }

  export const getFileExt = (fileName) => {
    const mtype     = fileName.split('.');
    const ext       = mtype[mtype.length - 1];
    return ext.toLowerCase();
  }

  export const camelCase = (data) =>
  !data || data === ''
    ? ''
    : data
        .split(' ')
        .map((d) => capitalizeCamel(d))
        .join(' ');

export const capitalizeCamel = (name) =>
  !name || name === ''
    ? ''
    : `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`;
