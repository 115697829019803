import { version } from '../../version.json';

const reloadPage = () => {
  window.location.reload();
};

/**
 * 
 * @param {String} BE_Version Version received from BackEnd
 */

export const checkVersion = (BE_Version) => {
  const [be1, be2, be3] = BE_Version.split('.');
  const [fe1, fe2, fe3] = version.split('.');
  if (+be1 > +fe1 || +be2 > +fe2 || +be3 > +fe3) return reloadPage();
  return;
};
