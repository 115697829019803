import { useEffect } from "react";
import { useState } from "react";
import { getOfflineTeeTimeBookSheet } from "../api/offlineTeeAPI";

const DisplaySheet = (props) => {

    const [isInit,                      setIsInit]                  = useState(true);
    const [holiday,                     setHoliday]                 = useState();
    const [bookSheet,                   setBookSheet]               = useState([]);
    const [pageData,                    setPageData]                = useState();

    useEffect(() => {
        props.title("First Come Basis Bookings", 'display-tee-sheet');
        fetchBookingSheet(); 
        $( "body" ).removeClass( "kt-subheader--solid kt-aside--enabled kt-aside--fixed" )
        $("div.kt-aside").remove();

    },[])

    useEffect(() => {
    
        fetchBookingSheet();
    
        const intervalId = setInterval(fetchBookingSheet, 3 * 60 * 1000); 
    
        return () => {
          clearInterval(intervalId);
        };
      }, []);

    useEffect(() => {       
        if (!isInit) fillDataTable("All");        
    }, [bookSheet]);
 
    const fetchBookingSheet = async()=> {
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            const date = moment().format('YYYY-MM-DD');
            const res  = await getOfflineTeeTimeBookSheet(date, 'Y');
            setPageData({ StartType: res.StartType, ServerDate: res.ServerDate, Members: res.Members });        
            setHoliday(res?.Holiday);
            
            (isInit) ? setIsInit(false) : $('#tblDisplaySheet').KTDatatable().destroy(); 
            setBookSheet(res.BookSheet);
                                    
            swal.close();
        } catch (err) {
            swal.close();
            swal.fire({ icon: 'error', titleText: err.message });  
        }
    }
   

    const fillDataTable =(teeBox)=> {       
        let tableFields = [];
        let teeSheet    = bookSheet;


        
        if(pageData.StartType == 'Shotgun'){
            tableFields = [
                { field: 'Session', title: 'Session', width: 70 },
                { field: 'HoleNumber', title: 'Hole', width: 50 },
            ]
        }else{
            tableFields = [
                { field: 'Slot', title: 'Slot', width: 90 },
                { field: 'TeeBox', title: 'Tee', width: 50 },
            ]
        }
       
        const dataTable = $('#tblDisplaySheet').KTDatatable({
            data        : { saveState: false, source: teeSheet },
            layout      : { scroll: false, footer: false },
            sortable    : false,
            pagination  : false,
            search      : { input: $('#generalSearch')},
            columns: [
                ...tableFields,
                { field: 'Player1', title: 'Player 1' },
                { field: 'Player2', title: 'Player 2' },
                { field: 'Player3', title: 'Player 3' },
                { field: 'Player4', title: 'Player 4'},
                {
                    field: 'CreatedDate', title: 'Time', width: 185,
                    template: (row)=> ((row.CreatedDate == null) ? '': moment(row.CreatedDate).format('DD-MMM hh:mm A')),                    
                },
            ],
            rows: { autoHide: false },
        });
        
        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblDisplaySheet .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');


    }

    return(
        <>
         <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex mx-4">
                                        <div className="form-group ">
                                            <div className="input-group date" >
                                                <div className={`col-xs-7  `}>
                                                    { ( <h5 id="otpTimer" className="margin-t10 margin-l40" style={{color: '#5d62a5', fontSize: '23px', fontWeight: '600', width:  '270px' , height:' 43px', border: '1px solid rgb(128 128 128 / 12%)', paddingLeft: '29px', paddingTop: '7px', boxShadow: '#9db2b99c 1px 4px 5px', backgroundColor: 'rgb(128 128 128 / 32%)'}}>{moment().format('D MMM, dddd')}</h5> )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {!!holiday && ( <h5 id="lblHoliday" className="lblHoliday mb-4"> {holiday.Name} </h5> )}
                            {bookSheet.length > 0 ? (
                                <div id="tblDisplaySheet" className="table table-bordered table-hover custom-datatable table-striped" style={{fontSize: "4em"}}></div>
                            ):(
                                <div style={{ textAlign: 'center', padding: '300px', fontSize: '3.5rem'}}>
                                    <h1>No Bookings Available</h1>
                                </div>
                            )}
                        </div>
                        <div className="kt-portlet__foot padding-tb15">
                            <div style={{ textAlign: 'center'}}>
                                <img src="https://golfezdev.s3.ap-south-1.amazonaws.com/Public/GBGC/Icon/GolfEZ-powered_PNG.png" height="15" ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DisplaySheet;