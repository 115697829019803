import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getEmailTemplateUrl                                = '/api/admin/golfClub/getEmailTemplate';
const postEmailTemplateUrl                               = '/api/admin/golfClub/postEmailTemplate';
const getHolidayCalenderAllUrl                           = '/api/admin/golfClub/getHolidayCalenderAll';
const postHolidayCalenderUrl                             = '/api/admin/golfClub/postHolidayCalender';
const getCourseClosedAllUrl                              = '/api/admin/golfClub/getCourseClosedAll';
const postCourseClosedUrl                                = '/api/admin/golfClub/postCourseClosed';
const getCourseAllUrl                                    = '/api/admin/golfClub/getCourseAll';
const getTeeBoxAllUrl                                    = '/api/admin/golfClub/getTeeBoxAll';
const postTeeBoxUrl                                      = '/api/admin/golfClub/postTeeBox';
const postCourseUrl                                      = '/api/admin/golfClub/postCourse';

export const getEmailTemplate = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet( getEmailTemplateUrl,{ headers: { auth_token: `bearer ${token}` } });
    return response;
};

export const postEmailTemplate = async (templateDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postEmailTemplateUrl,{ templateDetails }, { headers: { auth_token: `bearer ${token}` } });
    return response;
};

export const getCourseAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getCourseAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const getTeeBoxAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getTeeBoxAllUrl, { headers: { auth_token: `bearer ${token}` } });
    return response;
};

export const postTeeBox = async (teeBoxDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postTeeBoxUrl, { teeBoxDetails } ,{ headers: { auth_token: `bearer ${token}` } });
    return response;
};

export const getHolidayCalenderAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getHolidayCalenderAllUrl, { headers: { auth_token: `bearer ${token}` } });
    return response;
};

export const postHolidayCalender = async (calenderDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postHolidayCalenderUrl, { calenderDetails }, { headers: { auth_token: `bearer ${token}` } });
    return response;
};

export const getCourseClosedAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCourseClosedAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCourseClosed = async (courseDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCourseClosedUrl,
        { courseDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCourse = async (courseDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCourseUrl,
        { courseDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};