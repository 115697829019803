import axios from 'axios';
import { sleep, displayErrorSwal } from './common';
import { version } from '../../version.json'
import { setRefreshedCookie } from '../../assets/scripts/login-util';
import  { setAuthState } from "../../assets/scripts/login-util";

const tokenErrors = ['bearer undefined', "bearer false"];
const errorLogs = ['Session Expired', 'Authentication failed', 'User not found', 'User not authorized', 'Authetication Error',
                    'User not authenticated'
                  ];

const getErrorMessage = (err) => {
  if(err.response && err.response.data){ 
    let errMsg =  err.response.data.message;
    if(errorLogs.includes(errMsg)){
      displayErrorSwal(errMsg);
      window.open('/', '_self');
      setAuthState();
    }
    return errMsg;
  }else{
    return 'Unknown error';
  }
}

export const axiosPost = async (url, data, config = { headers:''}) => {
  if(config && tokenErrors.includes(config.headers.auth_token)) return sleep(2000);
  try {
    if (config)
      config.headers = {...config.headers, fe_version : version}
    const response  = await axios.post(url, data, config);

    if(response.headers['refresh_fe'] === 'true'){
      window.location.reload();
    }

    if(response.headers['refresh_cookie'] === 'true' && config){
        await setRefreshedCookie(config);
    }

    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || 'Unknown error');
  }
};

export const axiosGet = async (url, config = { headers:''}) => {
  if(config && tokenErrors.includes(config.headers.auth_token)) return sleep(2000);
  try {
    if (config)
      config.headers = {...config.headers, fe_version : version}
    const response = await axios.get(url, config);

    if(response.headers['refresh_fe'] === 'true'){
      window.location.reload();
    }

    if(response.headers['refresh_cookie'] === 'true' && config){
        await setRefreshedCookie(config);
    }

    return response.data;
  } catch (err) {
     
    const message = getErrorMessage(err);
    throw new Error(message || err.message || 'Unknown error');
  }
};

export const axiosPatch = async (url, data, config) => {
  if(config && tokenErrors.includes(config.headers.auth_token)) return sleep(2000);
  try {

    const response = await axios.patch(url, data, config);

    // if(response.headers['refresh_fe'] === 'true'){
    //   window.location.reload();
    // }

    if(response.headers['refresh_cookie'] === 'true' && config){
        await setRefreshedCookie(config);
    }
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || 'Unknown error');
  }
};

export const axiosDelete = async (url, config) => {
  if(config && tokenErrors.includes(config.headers.auth_token)) return sleep(2000);
  try {
    const response = await axios.delete(url, config);

    if(response.headers['refresh_cookie'] === 'true' && config){
        await setRefreshedCookie(config);
    }

    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || 'Unknown error');
  }
};
