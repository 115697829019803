import axios from 'axios';
import cookie from "react-cookies";
import {sessionSecret} from "../../env.json";
import { Encryptor } from "../../components/global/encryptor";

const { encrypt, decrypt } = Encryptor(sessionSecret);


//Admin Login State Setup --->
export const getAuthState = form => {
    const userCookie = cookie.load('CGC_GolfEZ_Admin');
    if(!userCookie) return false;
    const params = JSON.parse(decrypt(userCookie))

    // Check for 5 minutes before expiry 
    if(moment(params.expiresOn).subtract(5,'minutes').diff(moment()) < 0) return 'Expired'
    
    if(form === 'bool') return !!params.token;
    if(!!params[form]) return params[form]
    else return params
}

export const setAuthState = (authParams, isRemember) => {
    if(!authParams){ 
        return cookie.remove('CGC_GolfEZ_Admin', { path: '/' });
    }
    //Encrypt Data
    const encryptedData = encrypt(JSON.stringify(authParams));
    //Set 90days Cookie 
    let cookieOptions = !isRemember ? { path : '/', secure : true } : { path : '/', maxAge : 7776000, secure : true };
    cookie.save('CGC_GolfEZ_Admin', encryptedData, cookieOptions)
}

const checkExcludedPath = () => {
    const excludedPaths = ['login'];
    let check = true
    for (const path of excludedPaths){
        if(window.location.pathname.includes(path)){
             check = false;
        }
    }
    return check;
}
   
export const setRefreshedCookie = async (config) => {
    const safeToUpdate = checkExcludedPath();
    
    if( !safeToUpdate || !config ) return;
    try {
        const { data : updatedCookie } = await axios.get('/api/admin/getUpdatedToken', config);
        if(!updatedCookie) throw 'Authentication failed';
        //  Convert & Set Cookie Age (1day = 86400 sec)
        const maxAge = ( moment( updatedCookie.expiresOn ).diff( moment(), 'days') + 1 ) * 86400;

        //Encrypt Data
        const encryptedData = encrypt(JSON.stringify(updatedCookie));

        let cookieOptions = maxAge <= 86400 ? { path : '/', secure : true } : { path : '/', maxAge : maxAge, secure : true };
        cookie.save('CGC_GolfEZ_Admin', encryptedData, cookieOptions);
    } catch (err) {
        throw new Error(err.message || 'Authentication failed');
    }
}
