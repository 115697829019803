import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import RightSideDetails from "../global/rightSideDetails";
import Select from 'react-select';
import '../../assets/scripts/jquery-validation.init';
import { addUser, checkMembershipNoExist, getViewUserAll, verifyMembershipNo, verifyMobileNo } from "../api/usersAPI";
import { uploadPublicFile } from "../api/fileAPI";
import Member from "./member";
import defaultUserImg from '../../assets/images/user.jpg'
import UserDetails from "./userDetails";
import AddMember from "./addMember";
import EditUser from "./editUser";
import ImageCropper from "../global/imageCropper";

export const OverflowMenu = (props) => {
    return (
      <div className="dropdown-menu dropdown-menu-right" x-placement="top-end">
          <a className="dropdown-item" onClick={props.onEditClick}>
            <i className="la la-edit"></i>
            Edit
          </a>
      </div>
    );
};

const Users = (props) =>{

    const [usersList,                       setUsersList]                       = useState([]);
    const [userDetails,                     setUserDetails]                     = useState({Active: 'Y'});
    const [members,                         setMembers]                         = useState([]);
    const [memberDetails,                   setMemberDetails]                   = useState({});
    const [editingXid,                      setEditingXid]                      = useState(null);
    const [navState,                        setNavState]                        = useState('All Users');
    const [isInit,                          setIsInit]                          = useState(true);
    const [userType,                        setUserType]                        = useState('Individual');
    const [action,                          setAction]                          = useState('a');
    const [rightSideDetails,                setRightSideDetails]                = useState(false);
    const [addMember,                       setAddMember]                       = useState(false);
    const [refresh,                         setRefresh]                         = useState(false);
    const [userNavState,                    setUserNavState]                    = useState('Members');
    const [showUserDetails,                 setShowUserDetails]                 = useState();
    const [editUser,                        setEditUser]                        = useState(false);

    const [userPhotoBlob,                   setUserPhotoBlob]                   = useState(null);
    const [cropperData,                     setCropperData]                     = useState(null);

    const [genderOption,                    setGenderOption]                    = useState([{ value:  'Male', label: 'Male'}, { value:  'Female', label: 'Female'},]);
    const [memberTypeOption,                setMemberTypeOption]                = useState([{ value:  'Primary', label: 'Primary'}, { value:  'Spouse', label: 'Spouse'}, { value:  'Child', label: 'Child'},]);
    const [membershipCategoryOption,        setMembershipCategoryOption]        = useState([{ value:  'Casual Member', label: 'Casual Member'}, { value:  'Corporate Member', label: 'Corporate Member'}, { value:  'Foreign Diplomat', label: 'Foreign Diplomat'},{ value:  'Green Card', label: 'Green Card'}, { value:  'Hony Member', label: 'Hony Member'}, { value:  'Life Member', label: 'Life Member'},{ value:  'Mess Member', label: 'Mess Member'}, { value:  'Mid-Week Member', label: 'Mid-Week Member'}, { value:  'NRI', label: 'NRI'},{ value:  'Permanent Member', label: 'Permanent Member'}, { value:  'Tenure Member', label: 'Tenure Member'}]);
    
    useEffect(() => {
        props.title("Users", "user")
      
    }, []);

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            $('#txtDOB').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtDOB').on('change', function (event) {
                updateMemberDetails('DOB',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });     
            $('#txtSubscriptionExpiresOn').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtSubscriptionExpiresOn').on('change', function (event) {
                updateUsersDetails('SubscriptionExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });   
            $('#txtMembershipExpiresOn').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtMembershipExpiresOn').on('change', function (event) {
                updateUsersDetails('MembershipExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 
        }else{
            setUserDetails({Active: 'Y'});
            setMemberDetails({});
            setMembers([]);
            setAction('a');
            setUserType('Individual')
            setUserPhotoBlob(null);    
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();                  
        }
    }, [usersList]);

    useEffect(() => {       
        if (navState || refresh) {
            fetchUsers();                
        }
    }, [navState, refresh]);

    const fetchUsers = async() =>{
        try {
            const users = await getViewUserAll()   
            isInit ? setIsInit(false) : $('#tblUser').KTDatatable().destroy(); 
            navState == 'All Users' ? setUsersList(users) : setUsersList([...users.filter(f => f.Category == navState)]);
            userDetails && userDetails.ID && setUserDetails((d) => users.find(f => f.ID == d.ID))

        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }
    function fillDataTable() {

        const dataTable = $('#tblUser').KTDatatable({
            data: {
                saveState: false,
                source: usersList.filter(f => f.MemberType == 'Primary'),
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'FirstName',
                    title: 'User',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--${row.Category === 'Organization' ? 'success' : 'info'} ">  ${row.FirstName.substring(0, 1)}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.FirstName} ${row.LastName} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                                </div>`
                    }
                }, 
                {
                    field: 'MembershipNo',
                    title: 'Membership No',
                    width: 110,
                },
                {
                    field: 'MembershipCategory',
                    title: 'Membership Category',
                    width: 180,
                },

                {
                    field: 'Mobile',
                    title: 'Mobile',
                },
                {
                    field: 'IsAppUser',
                    title: 'App User',
                    template: function (row) {
                        return row.IsAppUser == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                  
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        });

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setAction('e')
                setUserDetails(dataTable.dataSet[index]);  
                setRightSideDetails(true);
            } 
        });

    }

    const addMembersHandler = async(e) => {
        e.preventDefault()

        var form = $('#add-member-form');
        
        form.validate({
            rules: {
                txtMembershipNo: {
                    required: true,
                },
                ddlMembershipCategory: {
                    required: true,
                },
                txtSubscriptionExpiresOn: {
                    required: true,
                },
                txtMembershipExpiresOn: {
                    required: true
                },    
                txtCompanyName: {
                    required: true,
                },
                txtFirstName: {
                    required: true
                },
                txtLastName: {
                    required: true
                },         
                txtMobile: {
                    required: true,
                    number: true
                },
                txtEmail: {
                    required: true,
                    email: true
                },
                ddlGender: {
                    required: true
                },
                txtDOB: {
                    required: true
                },
                ddlMemberType: {
                    required: true
                }                            
            },
            messages: {
                txtMembershipNo: {
                    required: "Please enter membershipNo"
                },
                ddlMembershipCategory: {
                    required: "Please select membership category"
                },
                txtSubscriptionExpiresOn: {
                    required: "Please select subscription Expires On"
                },
                txtMembershipExpiresOn: {
                    required: "Please select membership Expires On"
                },
                txtCompanyName: {
                    required: "Please enter company Name"
                },
                txtFirstName: {
                    required: "Please enter firstname"
                },
                txtLastName: {
                    required: "Please enter lastname"
                },
                txtMobile: {
                    required: "Please enter mobile",
                },
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                },
                ddlGender: {
                    required: "Please select gender"
                },
                txtDOB: {
                    required: "Please select date of birth"
                },
                ddlMemberType: {
                    required: "Please select member Type"
                }
            }
        });

        
        if (!form.valid()) return;
        if(userType !== 'Organization'){
            if ((memberDetails.MemberType == "Primary" || memberDetails.MemberType == "Spouse") && members.length > 0 && members.find((f, i) => i !== editingXid && f.MemberType == memberDetails.MemberType)) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Error!',
                    text                  : `Only 1 ${memberDetails.MemberType} member can be added`,
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                return
            }
        }

        if((members.length == 0 || editingXid == 0) && memberDetails.MemberType !== "Primary"){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : 'Kindly add primary member',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        try {
           
    
            await verifyMobileNo(memberDetails.Mobile)
            
   
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }
        setMembers((a) => {
            const newA = [...a];
            if (editingXid || editingXid == 0) {
                newA[editingXid] = {
                ...memberDetails,
                Photo: userPhotoBlob
                };
                setEditingXid(null);
            } else {
                newA.push({
                ...memberDetails,
                Photo: userPhotoBlob,
                Category: userType
                });
            }
            return newA;
        });
        
   
        setMemberDetails({});
        setUserPhotoBlob(null)


        form.clearForm();
        form.validate().resetForm();

    }

    const addUserDetailsHandler = async(e) => {
        e.preventDefault()

        if(members.length <= 0){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : `Please add atleast 1 member`,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
              });
            return
        }

        const btn = $('#btnSave');

        helper.StartProcessing(btn);

      try {

        const memberGroup = {
            Category:               userType,
            MembershipNo:           userDetails.MembershipNo,
            MembershipCategory:     userDetails.MembershipCategory,
            SubscriptionExpiresOn:  userDetails.SubscriptionExpiresOn,
            MembershipExpiresOn:    userDetails.MembershipExpiresOn,
            CompanyName:            userDetails.CompanyName || ''   
        }
        //users
        let userList = [];

        for (const m of members) {
            let userPhoto = '';
            //Call upload API -->
            if(m.Photo && m.Photo != ''){
                const res = await uploadPublicFile(m.Photo, 'User');
                userPhoto = res.fileName;            
            }

            userList.push({
                FirstName:    m.FirstName,
                MiddleName:   m.MiddleName || '',
                LastName:     m.LastName,
                Mobile:       m.Mobile,
                Email:        m.Email,
                Gender:       m.Gender,
                Photo:        userPhoto,
                DOB:          m.DOB || '',
                RFID:         m.RFID || '',
                BagTag:       m.BagTag || '',
                MemberType:   m.MemberType,
                Active:       userDetails.Active
            })
        }
        await addUser(userList, memberGroup);
        helper.StopProcessing(btn);
        closeDrawer();
        fetchUsers();
        swal.fire({
            icon: 'success',
            titleText: 'User added successfully!',
            showConfirmButton: false,
            timer: 1500,
        });
      } catch (err) {
         helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
      }

    }

    const updateMemberHandler = (index, value) => {
        setEditingXid(index); 
        setMemberDetails({...value, Photo: ''});
        setUserPhotoBlob(value.Photo)    };

    const removeMemberHandler = (index) => {
        if (index === editingXid) {
            setEditingXid(null);
            setMemberDetails({});
            setUserPhotoBlob(null);

          }
        setMembers((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
          });

    };
    
    const updateMemberDetails = (field, value) => { 
        setMemberDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateUsersDetails = (field, value) => {
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };
 
    const userTypeHandler = (type) => {
        setUserType(type);
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const updateUserHandler = () => {
        setAddMember(true)
    };

    const refetchUsers = () => setRefresh((r) => !r);
 
    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setUserPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    }
    
    const removePhotoHandler = async(e) => {
		e.preventDefault();
		setUserPhotoBlob(null)
	}
     return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <nav className="file-nav navbar navbar-top">
                                    <a className={navState === "All Users" ? "top-nav-active" : ""} onClick={() => setNavState("All Users")}>  
                                        All Users 
                                    </a>
                                    <a className={navState === "Individual" ? "top-nav-active" : "" } onClick={() => setNavState("Individual")}  >  
                                         Individual
                                    </a>
                                    <a className={navState === "Organization" ? "top-nav-active" : "" } onClick={() => setNavState("Organization")}  >  
                                        Organization
                                    </a>
                                </nav>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="row align-items-center margin-r10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button  onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New User</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblUser" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Users Details" onCloseDrawer={closeDrawer}>
                            {action == 'a' ? 
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <div className="padding-lr10">
                                            <form className="kt-form" id="add-member-form">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                    <div className="form-group row margin-b10 margin-t0">
                                                        <div className="kt-radio-inline radio-container" >
                                                            <div className="input-group"  style={{width: '15%'}}>
                                                                {' '}
                                                                <input
                                                                    value="Individual"
                                                                    defaultChecked={true}
                                                                    onChange={({ target }) => userTypeHandler(target.value)}
                                                                    type="radio"
                                                                    name="client-type"
                                                                    id="indv"
                                                                />
                                                                <label htmlFor="indv">Individual</label>
                                                            </div>
                                                            <div className="input-group "   style={{width: '11%'}} >
                                                                {' '}
                                                                <input
                                                                    value="Organization"
                                                                    defaultChecked={userType == 'Organization' }
                                                                    onChange={({ target }) => userTypeHandler(target.value)}
                                                                    type="radio"
                                                                    name="client-type"
                                                                    id="corp"
                                                                />
                                                                <label htmlFor="corp">Organization</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 form-group" >
                                                            <div className="input-group ">
                                                                <input 
                                                                    style={{ fontWeight: userDetails.MembershipNo ? 'bold' : '' }}
                                                                    id="txtMembershipNo" 
                                                                    name="txtMembershipNo" 
                                                                    type="text" 
                                                                    className="form-control"
                                                                    value={userDetails.MembershipNo  || ''}
                                                                    onChange={(event) => updateUsersDetails('MembershipNo', event.target.value)}
 
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        Membership No.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 form-group" >
                                                            <div >
                                                                <Select 
                                                                    id="ddlMembershipCategory" 
                                                                    name="ddlMembershipCategory"
                                                                    className="multi-custom"
                                                                    placeholder="Select Membership Category"
                                                                    value={userDetails && membershipCategoryOption.find((m) => m.value == userDetails.MembershipCategory )  || ''}
                                                                    onChange={(event) => updateUsersDetails('MembershipCategory', event.value)}
                                                                    options={membershipCategoryOption}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 form-group">
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtSubscriptionExpiresOn" 
                                                                    name="txtSubscriptionExpiresOn" 
                                                                    type="text" 
                                                                    className="form-control date-input" 
                                                                    placeholder="Select Subscription Expires On" 
                                                                    readOnly={true} 
                                                                    value={userDetails.SubscriptionExpiresOn ? moment(userDetails.SubscriptionExpiresOn).format('DD-MM-YYYY') : ''} 
                                                                    disabled={members.length == 0  || editingXid == 0  ?  false : true}

                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtMembershipExpiresOn" 
                                                                    name="txtMembershipExpiresOn" 
                                                                    type="text" 
                                                                    className="form-control date-input" 
                                                                    placeholder="Select Membership Expires On" 
                                                                    readOnly={true} 
                                                                    value={userDetails.MembershipExpiresOn ? moment(userDetails.MembershipExpiresOn).format('DD-MM-YYYY') : ''} 
                                                                    disabled={members.length == 0  || editingXid == 0  ?  false : true}

                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {userType == 'Organization' && (
                                                        <div className="row">
                                                            <div className="col-12 form-group ">
                                                                <input 
                                                                    id="txtCompanyName" 
                                                                    name="txtCompanyName" 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    placeholder="Company Name"
                                                                    value={userDetails.CompanyName || ''}
                                                                    onChange={(event) => updateUsersDetails('CompanyName', helper.camelCase(event.target.value))} 
                                                                />
                                                            </div>
                                                        </div>

                                                    )}
                                                    <div className="row">
                                                        <div className="col-6 form-group  padding-t30">
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed  offset-4" id="kt_user_add_avatar">
                                                                <div className="kt-avatar__holder" 
                                                                    style={{ backgroundImage : `url( ${userPhotoBlob ? URL.createObjectURL(userPhotoBlob): memberDetails.Photo ? memberDetails.Photo : defaultUserImg})` ,width: "120px", height:"120px" } }>    
                                                                </div>
                                                                <label 
                                                                    className="kt-avatar__upload" 
                                                                    data-toggle="kt-tooltip"  
                                                                    data-original-title="Change avatar"
                                                                    onClick ={openCropper}
                                                                >
                                                                    <i className="fa fa-pen"></i>
                                                                </label>
                                                                {(!!userPhotoBlob || memberDetails.Photo) && (
                                                                    <span className="kt-avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler} >
                                                                        <i className="fa fa-times"></i>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-12 form-group">
                                                                    <input 
                                                                        id="txtFirstName" 
                                                                        name="txtFirstName" 
                                                                        type="text" 
                                                                        className="form-control"
                                                                        placeholder="First Name"
                                                                        value={memberDetails.FirstName || ''}
                                                                        onChange={(event) => updateMemberDetails('FirstName',  helper.camelCase(event.target.value))} 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 form-group">
                                                                    <input 
                                                                        id="txtMiddleName" 
                                                                        name="txtMiddleName" 
                                                                        type="text" 
                                                                        className="form-control"
                                                                        placeholder="Middle Name"
                                                                        value={memberDetails.MiddleName || ''}
                                                                        onChange={(event) => updateMemberDetails('MiddleName',  helper.camelCase(event.target.value))} 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 form-group">
                                                                    <input 
                                                                        id="txtLastName" 
                                                                        name="txtLastName" 
                                                                        type="text" 
                                                                        className="form-control"
                                                                        placeholder="Last Name"
                                                                        value={memberDetails.LastName || ''}
                                                                        onChange={(event) => updateMemberDetails('LastName',  helper.camelCase(event.target.value))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 form-group ">
                                                            <Select 
                                                                id="ddlMemberType" 
                                                                name="ddlMemberType"
                                                                className="multi-custom"
                                                                placeholder="Select Member Type"
                                                                value={memberDetails && memberTypeOption.find((m) => m.value == memberDetails.MemberType )  || ''}
                                                                onChange={(event) => updateMemberDetails('MemberType', event.value)}
                                                                options={memberTypeOption}
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <input 
                                                                id="txtMobile" 
                                                                name="txtMobile" 
                                                                type="text" 
                                                                className="form-control"
                                                                minLength='10'
                                                                maxLength='10'
                                                                placeholder="Mobile"
                                                                value={memberDetails.Mobile || ''}
                                                                onChange={(event) => updateMemberDetails('Mobile', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-4 form-group ">
                                                            <input 
                                                                id="txtEmail" 
                                                                name="txtEmail" 
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="Email "
                                                                value={memberDetails.Email  || ''}
                                                                onChange={(event) => updateMemberDetails('Email', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3 form-group">
                                                            <Select 
                                                                id="ddlGender" 
                                                                name="ddlGender"
                                                                className="multi-custom"
                                                                placeholder="Select Gender"
                                                                value={memberDetails && genderOption.find((m) => m.value == memberDetails.Gender) || ''}
                                                                onChange={(event) => updateMemberDetails('Gender', event.value)}
                                                                options={genderOption}
                                                            />
                                                        </div>
                                                        <div className="col-3 form-group ">
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtDOB" 
                                                                    name="txtDOB" 
                                                                    type="text" 
                                                                    className="form-control date-input" 
                                                                    readOnly={true} 
                                                                    placeholder="Select DOB" 
                                                                    value={memberDetails.DOB ? moment(memberDetails.DOB).format('DD-MM-YYYY') : ''} 
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 form-group ">
                                                            <input 
                                                                id="txtRFID" 
                                                                name="txtRFID" 
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="RFID"
                                                                value={memberDetails.RFID || ''}
                                                                onChange={(event) => updateMemberDetails('RFID', event.target.value)} 
                                                            />
                                                        </div>
                                                        <div className="col-3 form-group ">
                                                            <input 
                                                                id="txtBagTag" 
                                                                name="txtBagTag" 
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="Bag Tag"
                                                                value={memberDetails.BagTag || ''}
                                                                onChange={(event) => updateMemberDetails('BagTag', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row" align='center'>
                                                        <div className={`offset-4 ${editingXid == 0 || editingXid ? 'col-4' : 'col-3'} margin-b30`}>
                                                            {editingXid == 0 || editingXid ? (
                                                                <div className="btn-group btn-block" role="group">
                                                                    <button
                                                                        id="btnAdd"
                                                                        className="btn btn-brand btn-block margin-l30"
                                                                        // style={{ maxWidth: '70%' }}
                                                                        onClick={addMembersHandler}
                                                                        >
                                                                        Update Member
                                                                    </button>
                                                                    <button
                                                                        style={{ maxWidth: '20%' }}
                                                                        className="btn btn-secondary btn-block margin-0 padding-0"
                                                                        onClick={() => {
                                                                            setEditingXid(null);
                                                                            setMemberDetails({});
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="la la-times"
                                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                        />
                                                                    </button>
                                                                </div>
                                                                ) : (
                                                                <button
                                                                    id="btnAdd"
                                                                    className="btn btn-label-brand btn-block margin-l30"
                                                                    onClick={addMembersHandler}
                                                                    >
                                                                    <i className="la la-plus" />
                                                                    Add Member
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {members && members.length > 0 &&
                                                    (
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                    <table className="kt-datatable__table table-striped">
                                                                        <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                                            {members.map((m, i) => (
                                                                                <tr key={i} className="kt-datatable__row block-row">
                                                                                    <td className="padding-l20 kt-datatable__cell text-clip">
                                                                                        <div className="kt-user-card-v2">
                                                                                            <div className="kt-user-card-v2__pic">
                                                                                                {m.Photo && m.Photo != '' ? 
                                                                                                <img src={URL.createObjectURL(m.Photo)} alt="user-profile" /> : 
                                                                                                <div className={`kt-badge kt-badge--xl kt-badge--${m.Category === 'Organization' ? 'success' : 'info'} `}> {m.FirstName.substring(0, 1)}</div>}
                                                                                            </div>
                                                                                            <div className="kt-user-card-v2__details text-clip">
                                                                                                <span className="kt-user-card-v2__name text-clip"> {`${m.FirstName} ${m.MiddleName || ''} ${m.LastName}`} </span> <span className="kt-user-card-v2__desc text-clip"> {m.Email} </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td width="15%" className="padding-l20 kt-datatable__cell text-clip">
                                                                                        <span>
                                                                                            {m.MemberType}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td width="15%" className="padding-l20 kt-datatable__cell text-clip">
                                                                                        <span>{m.Mobile || ''}</span>
                                                                                    </td>
                                                                                    <td width="15%" className="padding-l20 kt-datatable__cell text-clip">
                                                                                        <span>{m.DOB ?  moment(m.DOB).format('DD-MMM-YYYY') : ''}</span>
                                                                                    </td>
                                                                                    <td width="15%" className="text-right kt-datatable__cell text-clip">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                            onClick={() => updateMemberHandler(i, m)}
                                                                                            >
                                                                                            <i className="flaticon2-edit"></i>
                                                                                        </button>
                                                                                        {i > 0 ? (
                                                                                            <button
                                                                                            type="button"
                                                                                            onClick={() => removeMemberHandler(i)}
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                            >
                                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                            </button>
                                                                                        ) : (
                                                                                            <span style={{ marginRight: '38px' }}></span>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-12 "  align="center">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addUserDetailsHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10"  onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="row">
                                        <div className={`col-${userDetails.Category == "Organization" ? '6' : '12'}`}> 
                                            <div className="kt-portlet">
                                                <div className="kt-portlet__body">
                                                    <div className="kt-widget kt-widget--user-profile-3">
                                                        <div className="kt-widget__top">
                                                            {userDetails.Photo && userDetails.Photo != '' ? 
                                                                <img className="kt-widget__pic kt-widget__pic" src={userDetails.Photo} alt="user-profile"/> :
                                                                <div
                                                                    onClick={() => setShowUserDetails(userDetails)}
                                                                    className={`kt-widget__pic kt-widget__pic--${
                                                                        userDetails.Category === 'Organization'
                                                                        ? 'success'
                                                                        : 'brand'
                                                                    } kt-font-${
                                                                        userDetails.Category === 'Organization'
                                                                        ? 'success'
                                                                        : 'brand'
                                                                    } kt-font-boldest kt-hidden-`}>
                                                                    {userDetails.FirstName.slice(0, 1) +
                                                                        userDetails.LastName.slice(0, 1)}
                                                                </div>
                                                            }   
                                                            <div className="kt-widget__content">
                                                                <div className="kt-widget__head">
                                                                    <span className="kt-widget__username"  onClick={() => setShowUserDetails(userDetails)}>
                                                                        {userDetails.FirstName + ' ' + userDetails.MiddleName + ' ' + userDetails.LastName}{' '}
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        <span className={`kt-badge kt-badge--info kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                            {userDetails.MembershipCategory}
                                                                        </span>
                                                                    </span>
                                                                    <div className="kt-widget__action">
                                                                        <span className="dropdown">
                                                                            <a
                                                                                className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                                data-toggle="dropdown"
                                                                                aria-expanded="true">
                                                                                <i className="flaticon-more-1"></i>
                                                                            </a>
                                                                            <OverflowMenu
                                                                                onEditClick={() =>
                                                                                    {setEditUser(true)}
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="kt-widget__subhead padding-t0"  onClick={() => setShowUserDetails(userDetails)}>
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon2-calendar-3"></i>
                                                                        {userDetails.Category} 
                                                                    </span>
                                                                    <span className="padding-r10">
                                                                        <i className="flaticon2-user"></i>
                                                                        {userDetails.MembershipNo}
                                                                    </span>
                                                                    <br></br>
                                                                    {userDetails.Mobile && (
                                                                        <span className="padding-r10">
                                                                        <i className="flaticon2-phone"></i>
                                                                        {userDetails.Mobile}
                                                                        </span>
                                                                    )}
                                                                    {userDetails.Email && (
                                                                        <span className="padding-r10">
                                                                            <i className="flaticon2-new-email"></i>
                                                                            {userDetails.Email}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {userDetails.Category == "Organization" && (
                                            <div className="col-md-6">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__body">
                                                        <div className="kt-widget kt-widget--user-profile-3">
                                                            <div className="kt-widget__top">
                                                                <div
                                                                    className={`kt-widget__pic kt-widget__pic--${
                                                                        userDetails.Category === 'Organization'
                                                                        ? 'success'
                                                                        : 'brand'
                                                                    } kt-font-${
                                                                        userDetails.Category === 'Organization'
                                                                        ? 'success'
                                                                        : 'brand'
                                                                    } kt-font-boldest kt-hidden-`}>
                                                                    {userDetails.CompanyName.slice(0, 1)}
                                                                </div>
                                                                <div className="kt-widget__content">
                                                                    <div className="kt-widget__head">
                                                                        <span className="kt-widget__username">
                                                                            {userDetails.CompanyName
                                                                            }{' '}
                                                                        </span>
                                                                        <div className="kt-widget__action">
                                                                            <span className="dropdown">
                                                                                <a
                                                                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                                    data-toggle="dropdown"
                                                                                    aria-expanded="true">
                                                                                </a>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="kt-widget__subhead padding-t0">
                                                                        <span className="padding-r10">
                                                                            <i className="flaticon2-calendar-3"></i>
                                                                            {userDetails.Category}
                                                                        </span>
                                                                        <br></br>
                                                                        <span className="padding-r10">
                                                                            <i className="flaticon2-new-email"></i>
                                                                            {userDetails.Email}
                                                                        </span>
                                                                        {userDetails.Mobile && (
                                                                            <span className="padding-r10">
                                                                                <i className="flaticon2-phone"></i>
                                                                                {userDetails.Mobile}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="kt-portlet kt-portlet--mobile">
                                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                            <div className="kt-portlet__head-label">
                                                <nav className="file-nav navbar navbar-top">
                                                    <a className={userNavState === "Members" ? "top-nav-active" : "" } onClick={() => setUserNavState("Members")}  >  
                                                        Members
                                                    </a>
                                                </nav>
                                            </div>
                                            <div className="kt-portlet__head-toolbar">
                                                <div className="kt-portlet__head-wrapper">
                                                    <div className="kt-portlet__head-actions">
                                                        {userNavState === 'Members' && (
                                                            <button className="btn btn-label-brand btn-block margin-0"
                                                                onClick={(e) => {e.preventDefault(), setAddMember(true)}}
                                                            >
                                                                <i className="la la-plus"></i>
                                                                Add Member
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__body padding-t0">
                                            {(userNavState === 'Members') && (
                                                <Member
                                                    members = {usersList.filter(f => f.GroupID == userDetails.GroupID)}
                                                    updateUserHandler={() => updateUserHandler()}
                                                    setShowUserDetails={setShowUserDetails}
                                                    setMemberDetails={setMemberDetails}

                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            }

                            </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showUserDetails && (
                <UserDetails
                    show={showUserDetails}
                    onDismissModal={setShowUserDetails}                   
                    user={showUserDetails}                   
                />
            )}
            {addMember && (
                <AddMember
                    show={addMember}
                    members = {usersList.filter(f => f.GroupID == userDetails.GroupID)}
                    refetchUsers={() => refetchUsers()}
                    onDismissModal={setAddMember}                   
                    GroupID={userDetails.GroupID}                   
                    memberDetails={memberDetails || ''}
                    setMemberDetails={setMemberDetails}                   
                />
            )}
            {editUser && (
                <EditUser
                    show={editUser}
                    refetchUsers={() => refetchUsers()}
                    onDismissModal={setEditUser}                          
                    userDetails={userDetails}                          
                />
            )}
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
     )
}
export default Users;