import { useState } from "react";
import { useEffect } from "react";
import { postMember, verifyMobileNo } from "../api/usersAPI";
import swal from 'sweetalert2';
import Select from 'react-select';
import * as helper from '../global/helper';
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";
import defaultUserImg from '../../assets/images/user.jpg'

const AddMember = (props) =>{
    
    const [memberDetails,               setMemberDetails]            = useState({Active: 'Y'});
    const [cropperData,                 setCropperData]              = useState(null);
    const [userPhotoBlob,               setUserPhotoBlob]            = useState(null);
    const [genderOption,                setGenderOption]             = useState([{ value:  'Male', label: 'Male'}, { value:  'Female', label: 'Female'},]);
    const [memberTypeOption,            setMemberTypeOption]         = useState([{ value:  'Primary', label: 'Primary'}, { value:  'Spouse', label: 'Spouse'}, { value:  'Child', label: 'Child'},]);
    

    useEffect(() => {
        $('#add-member-modal').modal({
            backdrop: 'static',
        });
        $('#add-member-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
        $('#txtDOB').datepicker({
        format: 'dd-mm-yyyy',
        minDate: new Date(),
        });
        $('#txtDOB').on('change', function (event) {
            updateMemberDetails('DOB',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }); 
    }, [])

    
    useEffect(() => {
        if (Object.keys(props.memberDetails).length > 0) {
            setMemberDetails(props.memberDetails)
        }
    }, [props.memberDetails])   


    const addMembersHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-member-form');
        
        form.validate({
            rules: {
                txtCompanyName: {
                    required: true,
                },
                txtFirstName: {
                    required: true
                },
                txtLastName: {
                    required: true
                },         
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                },
                txtEmail: {
                    required: true,
                    email: true
                },
                ddlGender: {
                    required: true
                },
                txtDOB: {
                    required: true
                },
                ddlMemberType: {
                    required: true
                }                            
            },
            messages: {
                txtCompanyName: {
                    required: "Please enter company Name"
                },
                txtFirstName: {
                    required: "Please enter firstname"
                },
                txtLastName: {
                    required: "Please enter lastname"
                },
                txtMobile: {
                    required: "Please enter mobile",
                },
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                },
                ddlGender: {
                    required: "Please select gender"
                },
                txtDOB: {
                    required: "Please select date of birth"
                },
                ddlMemberType: {
                    required: "Please select member type"
                }
            }
        });

        
        if (!form.valid()) return;
        if(props.members.find((f, i) => f.Category  !== 'Organization')){
            if ((memberDetails.MemberType == "Primary" || memberDetails.MemberType == "Spouse") && props.members.find(f => f.ID !== memberDetails.ID && f.MemberType == memberDetails.MemberType)) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Error!',
                    text                  : `Only 1 ${memberDetails.MemberType} member can be added`,
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                return
            }
        }

        if(props.members[0].ID == memberDetails.ID && props.members[0].MemberType !== memberDetails.MemberType){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : 'Kindly add primary member',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        helper.StartProcessing(btn);

        try {
            let userPhoto = '';
            //Call upload API -->
            if(userPhotoBlob){
                const res = await uploadPublicFile(userPhotoBlob, 'User');
                userPhoto = res.fileName;  
            }else{
                if(memberDetails.Photo) userPhoto = memberDetails.Photo.split('/User/')[1];
            }
           
            const member = {
                ID:                     memberDetails.ID || 0,
                FirstName:              memberDetails.FirstName,
                MiddleName:             memberDetails.MiddleName || '',
                LastName:               memberDetails.LastName,
                Mobile:                 memberDetails.Mobile,
                Email:                  memberDetails.Email,
                Gender:                 memberDetails.Gender,
                Photo:                  userPhoto || '',
                DOB:                    memberDetails.DOB,
                RFID:                   memberDetails.RFID || '',
                BagTag:                 memberDetails.BagTag || '',
                MemberType:             memberDetails.MemberType,
                GroupID:                props.GroupID,
                isPrimaryActive:        props.members && props.members.find((f) => f.MemberType == "Primary").Active == "Y" ? "Y" : "N",
                isLastPrimaryActive:    memberDetails.Category == "Organization" ?( props.members && props.members.filter((f) => f.MemberType == "Primary" && f.Active == "Y" && f.Category == "Organization").length == 1  ? "Y" : "N") : "I",
                Active:                 memberDetails.Active    
            };
            await postMember(member)
            helper.StopProcessing(btn);

            $('#add-member-modal').modal('hide');
            if (memberDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Member updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Member added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            props.refetchUsers()
            
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        props.setMemberDetails({});

        form.clearForm();
        form.validate().resetForm();

    }
    
    const updateMemberDetails = (field, value) => { 
        setMemberDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        props.setMemberDetails({});
        setUserPhotoBlob(null);      

    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setUserPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    } 

    const removePhotoHandler = async(e) => {
		e.preventDefault();	
        setUserPhotoBlob(null)
        setMemberDetails((d) => {
            return { ...d, Photo: '' };
        });
	}

    return(
        <>
            <div className="modal fade"id="add-member-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Member</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDrawer}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-member-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="row">
                                        <div className="col-6 form-group  padding-t30 padding-r30">
                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed  offset-4" id="kt_user_add_avatar">
                                                <div className="kt-avatar__holder" 
                                                    style={{ backgroundImage : `url( ${userPhotoBlob ? URL.createObjectURL(userPhotoBlob): memberDetails.Photo ? (memberDetails.Photo): defaultUserImg})` ,width: "130px", height:"130px" } }>    
                                                </div>
                                                <label 
                                                    className="kt-avatar__upload" 
                                                    data-toggle="kt-tooltip"  
                                                    data-original-title="Change avatar"
                                                    onClick ={openCropper}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </label>
                                                {(!!userPhotoBlob || memberDetails.Photo) && (
                                                    <span className="kt-avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler} >
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-12 form-group" style={{marginBottom: '1rem'}}>
                                                    <label>First Name</label>
                                                    <input 
                                                        id="txtFirstName" 
                                                        name="txtFirstName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={memberDetails.FirstName || ''}
                                                        onChange={(event) => updateMemberDetails('FirstName',  helper.camelCase(event.target.value))} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 form-group" style={{marginBottom: '1rem'}}>
                                                    <label>Middle Name</label>
                                                    <input 
                                                        id="txtMiddleName" 
                                                        name="txtMiddleName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={memberDetails.MiddleName || ''}
                                                        onChange={(event) => updateMemberDetails('MiddleName',  helper.camelCase(event.target.value))} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 form-group" style={{marginBottom: '1rem'}}>
                                                    <label>Last Name</label>
                                                    <input 
                                                        id="txtLastName" 
                                                        name="txtLastName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={memberDetails.LastName || ''}
                                                        onChange={(event) => updateMemberDetails('LastName',  helper.camelCase(event.target.value))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Member Type</label>
                                            <Select 
                                                id="ddlMemberType" 
                                                name="ddlMemberType"
                                                className="multi-custom"
                                                value={memberDetails && memberTypeOption.find((m) => m.value == memberDetails.MemberType )  || ''}
                                                onChange={(event) => updateMemberDetails('MemberType', event.value)}
                                                options={memberTypeOption}

                                            />
                                        </div>
                                        <div className="col-4 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Mobile</label>
                                            <input 
                                                id="txtMobile" 
                                                name="txtMobile" 
                                                type="text" 
                                                minLength='10'
                                                maxLength='10'
                                                className="form-control"
                                                value={memberDetails.Mobile || ''}
                                                onChange={(event) => updateMemberDetails('Mobile', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-4 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Email</label>
                                            <input 
                                                id="txtEmail" 
                                                name="txtEmail" 
                                                type="text" 
                                                className="form-control"
                                                value={memberDetails.Email  || ''}
                                                onChange={(event) => updateMemberDetails('Email', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Gender</label>
                                            <Select 
                                                id="ddlGender" 
                                                name="ddlGender"
                                                className="multi-custom"
                                                value={memberDetails && genderOption.find((m) => m.value == memberDetails.Gender) || ''}
                                                onChange={(event) => updateMemberDetails('Gender', event.value)}
                                                options={genderOption}
                                            />
                                        </div>
                                        <div className="col-3 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Date Of Birth</label>
                                            <div className="input-group date">
                                                <input 
                                                    id="txtDOB" 
                                                    name="txtDOB" 
                                                    type="text" 
                                                    className="form-control date-input" 
                                                    readOnly={true} 
                                                    value={memberDetails.DOB ? moment(memberDetails.DOB).format('DD-MM-YYYY') : ''} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 form-group" style={{marginBottom: '1rem'}}>
                                            <label>RFID</label>
                                            <input 
                                                id="txtRFID" 
                                                name="txtRFID" 
                                                type="text" 
                                                className="form-control"
                                                value={memberDetails.RFID || ''}
                                                onChange={(event) => updateMemberDetails('RFID', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-3 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Bag Tag</label>
                                            <input 
                                                id="txtBagTag" 
                                                name="txtBagTag" 
                                                type="text" 
                                                className="form-control"
                                                value={memberDetails.BagTag || ''}
                                                onChange={(event) => updateMemberDetails('BagTag', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-6 form-group" style={{marginBottom: '1rem'}}>
                                        <label>RFID</label>
                                            <input 
                                                id="txtRFID" 
                                                name="txtRFID" 
                                                type="text" 
                                                className="form-control"
                                                value={memberDetails.RFID || ''}
                                                onChange={(event) => updateMemberDetails('RFID', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-6 form-group" style={{marginBottom: '1rem'}}>
                                        <label>Bag Tag</label>
                                            <input 
                                                id="txtBagTag" 
                                                name="txtBagTag" 
                                                type="text" 
                                                className="form-control"
                                                value={memberDetails.BagTag || ''}
                                                onChange={(event) => updateMemberDetails('BagTag', event.target.value)} 
                                            />
                                        </div>
                                    </div> */}
                                    <div className="form-group row" style={{marginBottom: '1rem'}}>
                                        <label className="col-form-label col-lg-6">Active</label>
                                        <div className="col-xl-6 col-lg-6">
                                            <span className="kt-switch kt-switch--icon">
                                                <label>
                                                    <input 
                                                        id="chkActive" 
                                                        name="chkActive" 
                                                        type="checkbox"
                                                        // defaultChecked={memberDetails.Active == 'N' ? '' : 'checked'}
                                                        defaultChecked={props.memberDetails && props.memberDetails.Active == 'N' ? '' : 'checked'}
                                                        onChange={(event) => updateMemberDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                    />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div> 
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                onClick={closeDrawer}
                            >
                                Close
                            </button>
                            <button
                                id="btnSave"
                                onClick={addMembersHandler}
                                type="button"
                                className={`btn btn-brand margin-0 `}
                            >                  
                                {props.memberDetails.FirstName ? 'Update Member' : 'Add Member'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}
export default AddMember;