import { useState, useEffect, useLayoutEffect } from "react";
import Select from 'react-select';
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import { getOfflineTeeTimeBookSheet, updateOfflineBooking, cancelOfflineBooking } from  '../api/offlineTeeAPI';

const TeeBoxes = [
    { value: 'All', label: 'All'},
    { value: '1',   label: '1'}, 
    { value: '10',  label: '10'}
];

const OfflineBookingSheet = (props) => {

    const [TeeBoxOption,                setTeeBoxOption]            = useState(TeeBoxes);
    const [rightSideDetails,            setRightSideDetails]        = useState(false);
    const [isInit,                      setIsInit]                  = useState(true);

    const [selectedDate,                setSelectedDate]            = useState(moment().format('DD-MM-YYYY'));
    const [selectedTeeBox,              setSelectedTeeBox]          = useState(TeeBoxes[0]);

    const [holiday,                     setHoliday]                 = useState();
    const [bookSheet,                   setBookSheet]               = useState([]);
    const [pageData,                    setPageData]                = useState();

    const [selectedUser,                setSelectedUser]            = useState();
    const [selectedBook,                setSelectedBook]            = useState();
    const [userList,                    setUserList]                = useState([]);       
    const [bookingTable,                setBookingTable]            = useState(null);

    useEffect(() => {
        props.title("First Come Basis Sheet", 'offline-tee-sheet');
        $('#txtDate').datepicker({ format: 'dd-mm-yyyy', autoclose: true});
        $('#txtDate').on('change',(event) => setSelectedDate(event.target.value)); 
    },[])

    useEffect(()=>{
        if(selectedDate) fetchBookingSheet();        
    },[selectedDate])

    useEffect(() => {       
        if (!isInit && selectedTeeBox) fillDataTable(selectedTeeBox.value);        
    }, [bookSheet]);
   
    useLayoutEffect(()=>{        
        if(rightSideDetails) Config.Core.OpenDrawer();     
    },[rightSideDetails])

    //API Calls ---

    const fetchBookingSheet = async()=> {
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            const date = moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
            const res  = await getOfflineTeeTimeBookSheet(date, 'N');
            
            setSelectedTeeBox(TeeBoxes[0]);
            setPageData({ StartType: res.StartType, ServerDate: res.ServerDate, Members: res.Members });        
            setHoliday(res?.Holiday);
            
            (isInit) ? setIsInit(false) : $('#tblBookSheet').KTDatatable().destroy(); 
            setBookSheet(res.BookSheet);
                                    
            swal.close();
        } catch (err) {
            swal.close();
            swal.fire({ icon: 'error', titleText: err.message });  
        }
    }
  
    const updateBooking = async()=> {
        if(userList.length < 2)  return swal.fire({ icon:'warning', titleText:'Minimum 2 Members Required' });
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
       
        try {         
            const book = { ID: selectedBook.ID, BookDate: selectedBook.BookDate, Comment: selectedBook.Comment };        
            
            const response = await updateOfflineBooking(book, userList);
                                    
            if(response.Message == 'fail'){
                return swal.fire({ icon: 'warning', titleText: response.LongMessage });             
            }

            swal.fire({ icon: 'success', titleText: 'Tee Time Updated Successfully'});               

            setTimeout(()=>{
                closeDrawer();
                fetchBookingSheet();
            }, 150);
        } catch (err) {
            swal.close();
            swal.fire({ icon: 'error', titleText: err.message }); 
        }
    }

    const cancelBooking = async()=> {
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
              
        try {                                  
            const book     = selectedBook;
            const response = await cancelOfflineBooking(book.ID);
                                    
            if(response.Message == 'fail'){
                return swal.fire({ icon: 'warning', titleText: response.LongMessage });             
            }

            swal.fire({ icon: 'success', titleText: 'Tee Time Cancelled Successfully'});               

            setTimeout(()=>{
                closeDrawer();
                fetchBookingSheet();
            }, 150);
        } catch (err) {
            swal.close();
            swal.fire({ icon: 'error', titleText: err.message }); 
        }
    }

    function exportSheet(btnType) {
        let fileName = `${moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} - Tee Booking Sheet - ${selectedTeeBox.value + ' Tee Box'}`;

        bookingTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: fileName // pass in a new set of properties
        });
        
        if (btnType == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        
        $('.tableexport-caption').addClass('hidden');
    }

    //Helpers ---
    const fillDataTable =(teeBox)=> {       
        let tableFields = [];
        let teeSheet    = bookSheet;

        if(teeBox != 'All') teeSheet = teeSheet.filter(i => i.TeeBox == teeBox);

        
        if (holiday != null) {
            $('#lblHoliday').text("Holiday : " + holiday.Name);
        } else {
            $('#lblHoliday').text("");
        }
        
        //EDIT         
        const isEdit = moment(moment(pageData.ServerDate).format('DD-MM-YYYY')).isSameOrBefore(selectedDate);      
        
        if(pageData.StartType == 'Shotgun'){
            tableFields = [
                { field: 'Session', title: 'Session', width: 70 },
                { field: 'HoleNumber', title: 'Hole', width: 50 },
            ]
        }else{
            tableFields = [
                { field: 'Slot', title: 'Slot', width: 70 },
                { field: 'TeeBox', title: 'Tee', width: 50 },
            ]
        }
       
        const dataTable = $('#tblBookSheet').KTDatatable({
            data        : { saveState: false, source: teeSheet },
            layout      : { scroll: false, footer: false },
            sortable    : false,
            pagination  : false,
            search      : { input: $('#generalSearch')},
            columns: [
                ...tableFields,
                {
                    field: 'Status', title: 'Status', width: 85,
                    template: function (row) {
                        if (row.Status == null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return '<div class="badge badge-danger"><span>' + row.Status + '</span></div>';
                        }
                    }
                },
                { field: 'Player1', title: 'Player 1' },
                { field: 'Player2', title: 'Player 2' },
                { field: 'Player3', title: 'Player 3' },
                { field: 'Player4', title: 'Player 4'},
                {
                    field: 'CreatedDate', title: 'Time', width: 145,
                    template: (row)=> ((row.CreatedDate == null) ? '': moment(row.CreatedDate).format('DD-MMM hh:mm:ss.SS A')),                    
                },
                {
                    field: '', title: '', width: 60,
                    template: (row)=> ((row.ID && (row.ID !=0))? showEditButton(row, isEdit): ''),                                        
                },
            ],
            rows: { autoHide: false },
        });
        
        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblBookSheet .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        // export to excel
        const BookingTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Tee Booking Sheet - ${selectedTeeBox.value} Tee Box`,
            });
            setBookingTable(BookingTable);
            $('.tableexport-caption').addClass('hidden');
            
        dataTable.on('click', '.btn-edit', function (t) {
            const index     = $(t.currentTarget).parents('tr').index();
            const book      = dataTable.dataSet[index];
            const bookUsers = [];
            
            if (book) {                                     
                for (let i = 1; i <= book.UserCount; i++) {                    
                    const playerId = book[`Player${i}ID`];
                    const player   = pageData.Members.find(m => m.UserID == playerId)                    
                    if(playerId) bookUsers.push({...player, UserType: 'User'});                                                          
                }
                                               
                setSelectedBook(book);  
                setUserList(bookUsers);                                
                setRightSideDetails(true); 
            } 
        });

    }

    const showEditButton = (row, isEdit) => {      
        const { Comment, StartType } = row;

        let optString = '';
        optString += '<center>'

        if(Comment) optString += `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-2" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left"  data-content="${row.Comment}"><i class="fa fa-info"></i></button>`
        if(isEdit) optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`
        // if(isEdit && StartType != 'Shotgun' ) optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`

        optString += '</center>'

        return optString;
    }

    const onTeeBoxChange =(t)=> {
        setSelectedTeeBox(t);
        (isInit) ? setIsInit(false) : $('#tblBookSheet').KTDatatable().destroy(); 
        
        fillDataTable(t.value);
    }

    const onInputChange =(key, value)=> setSelectedBook(d => ({...d, [key]:value}));
  
    const addBookingUser=()=>{
        if(!selectedUser) return;
        const { value:newUser } = selectedUser;
        
        if(userList.length > 3) return swal.fire({ icon: 'warning', titleText: 'Maximum 4 Players Allowed' });
        
        const isExist = userList.find( u => u.UserID == newUser.UserID);
        if(isExist) return swal.fire({ icon: 'warning', titleText: 'Member Already Added' });
        
        setUserList(d => ([...d, { UserID: newUser.UserID, UserType: 'User', FirstName: newUser.FirstName, MiddleName: newUser.MiddleName, LastName: newUser.LastName, MembershipNo : newUser.MembershipNo }]));
        setSelectedUser(); //Clear UserDD
    }

    const deleteUser  = (userId) => {
        const newList = userList.filter( u => u.UserID != userId);        
        setUserList(newList);
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {setRightSideDetails(false) }, 100);
        setSelectedBook();
        setUserList([]);
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex mx-4">
                                        <label className="col-form-label  text-right mr-2">Date </label>
                                        <div className="form-group ">
                                            <div className="input-group date">
                                                <input 
                                                    id="txtDate" type="text" name="txtDate" 
                                                    className="form-control" placeholder="Select date" 
                                                    defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {pageData?.StartType == 'Slot' && (<div className="d-flex  mx-4">
                                        <label className="col-form-label text-right pnlSlot  mr-2"> Tee Box </label>
                                        <div className="form-group pnlSlot width-140">
                                            <div>
                                                <Select 
                                                    id="ddlTeeBox" name="ddlTeeBox" className="multi-custom"
                                                    defaultValue={ selectedTeeBox}
                                                    onChange={(t) => onTeeBoxChange(t)}
                                                    options={TeeBoxOption}
                                                    />
                                            </div>
                                        </div>
                                    </div>)}
                                    
                                    <div className="form-group  mx-4">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    id="generalSearch" type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Tee Time..." 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-sm-2">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('xlsx')}
                                                >Excel
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={ () => exportSheet('csv')}
                                                >Csv 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 id="lblHoliday" className="lblHoliday"></h5>
                            
                            <div id="tblBookSheet" className="table table-bordered table-hover custom-datatable"></div>

                            {rightSideDetails && (<RightSideDetails title="Book Tee Time" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form id="ModalHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Date</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="lblDate" type="text" name="lblDate" 
                                                        className="form-control" disabled="disabled" 
                                                        value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                    />
                                                </div>
                                            </div>
                                            
                                                {(!!pageData && pageData.StartType == 'Shotgun') ? (<>                                                         
                                                    <div className="form-group row">                                    
                                                        <label className="col-form-label col-lg-3"> Start Time </label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="lblStartTime" name="lblStartTime" type="text" className="form-control" disabled="disabled" 
                                                                value={selectedBook?.StartTime || ''} 
                                                            />
                                                        </div>
                                                    </div>                                                                          
                                                    <div className="form-group row">                                    
                                                        <label className="col-form-label col-lg-3"> Hole No. </label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="lblHoleNo" name="lblHoleNo" type="text" className="form-control" disabled="disabled" 
                                                                value={selectedBook?.HoleNumber || ''} 
                                                            />
                                                        </div>
                                                    </div>
                                                </>):(<>                                   
                                                    <div className="form-group row">                                    
                                                        <label className="col-form-label col-lg-3"> Tee Box </label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="lblTeeBox" name="lblTeeBox" type="text" className="form-control" disabled="disabled" 
                                                                value={selectedBook?.TeeBox || ''} 
                                                            />
                                                        </div>
                                                    </div>                                                  
                                                    <div id="pnlEdit" className="form-group row">
                                                        <label className="col-form-label col-lg-3"> Slot <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8"> 
                                                            <input 
                                                                id="lblSlot" name="lblSlot" type="text" className="form-control" disabled="disabled" 
                                                                value={selectedBook?.Slot || ''} 
                                                            />                                                                                
                                                        </div>
                                                    </div>                                                                                 
                                                </>)}

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Member </label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group" >
                                                            <div style={{width: "80%"}} >
                                                                <Select
                                                                    id = "ddlUser" name = "ddlUser" className = "ddlUser" placeholder = "Select User" 
                                                                    options  = {pageData?.Members?.map(c=>({label: `${c.FirstName} ${c.LastName} (${c.Mobile})`, value: c }))}
                                                                    value    = {selectedUser || ''}                                                    
                                                                    onChange = {(event) => setSelectedUser(event)}
                                                                />
                                                            </div>
                                                            <div className="input-group-append" style={{zIndex: 0}}>
                                                                <button className="btn btn-secondary kt-select-reset" type="button" onClick={()=> setSelectedUser()}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                            </div>
                                                            <button id="btnAddUser" className="btn btn-label-brand margin-l10 margin-r0" type="button" 
                                                                onClick={addBookingUser}
                                                            > Add </button>                                                                                                       
                                                        </div>
                                                    </div>
                                                </div>    

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Comment</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id ="txtComment" rows="3"name="txtComment" className="form-control" 
                                                            value = {selectedBook?.Comment || ''}
                                                            onChange = {({target}) => onInputChange('Comment', target.value)} 
                                                        ></textarea>
                                                    </div>
                                                </div>
                                        </form>

                                        <div className="padding-lr50">                                        
                                            <table id="table-user" className="table table-bordered table-hover margin-b30">
                                                <thead className="light-grey-background">
                                                    <tr>
                                                        <th>Players</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                {userList && (userList.length > 0) &&
                                                    <tbody>
                                                        {userList.map((m,i) => (
                                                            <tr key={m.UserID}>
                                                                <td >{`${m.FirstName} ${m.LastName} (${m.MembershipNo})`} </td>
                                                                <td className="text-center"> 
                                                                    {(i>1) && (
                                                                        <span className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete" 
                                                                            onClick={()=> deleteUser(m.UserID)}
                                                                        > <i className="flaticon2-rubbish-bin-delete-button"></i>             
                                                                        </span>                                                               
                                                                    )}                                                               
                                                                </td>
                                                            </tr>)
                                                        )}
                                                    </tbody>
                                                }
                                                <tfoot></tfoot>
                                            </table>
                                        </div>                                        
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-12 text-right">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={updateBooking}> Update Booking </button>
                                                    <button id="btnCancel" type="button" className="btn btn-danger d-inline-block margin-l10" onClick={cancelBooking}> Cancel Booking </button>
                                                    <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}> Close </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            
        </>

    )
}


export default OfflineBookingSheet;