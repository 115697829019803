import { useState, useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getOfflineStaggeredBlockAll, postOfflineStaggeredBlock } from "../api/offlineTeeAPI"

const sessionType = ["Morning","Afternoon"];

const OfflineStaggeredBlock = (props) => {
 
    const [allBlocks, setAllBlocks] = useState([]);
    const [newBlock, setNewBlock]   = useState({ Session : "Morning" });
    const [isEdit, setIsEdit]       = useState(false);

    useEffect(() => {
        props.title("Staggered Block", "offline-tee-block");       
        fetchStaggeredBlock();

        //Sorting Function -->
        AllowSortable();       
    }, []);
      
    const dataHandler = (field,value) => setNewBlock(d => ({...d, [field]: value}));

    const editHandler = (id) => {      
        setIsEdit(true);
        const d = allBlocks.find( a => a.ID == id);
        setNewBlock(d);
    }

    const postBlock = async() => {
      
        const btn  =  (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addBlockForm');
     
        form.validate({
            rules: { txtBlock: { required: true }},
            messages: { txtBlock : { required: "Please enter block name" }}
        });

        if (!form.valid()) return;
        //Validation Goes Here -->

        helper.StartProcessing(btn);
        try {
            if(!isEdit) newBlock.Index = allBlocks.length + 1;       
            
            saveBlocks([newBlock]);                  
            
            helper.StopProcessing(btn);                  
            if (newBlock.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Block updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2500,  
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Block added successfully!',
                    showConfirmButton: false,
                    timer: 2500,
                    onOpen: () => swal.hideLoading()    
                });
            }
            
            //Clear State ->
            clearAction();
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const saveBlocks = async(blocks) => {
        if(blocks.length <= 0) return;

        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
            
            const res = await postOfflineStaggeredBlock(blocks);          
            setAllBlocks(res);
                    
            swal.close()
        } catch (err) {
           swal.close()
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;  
        }
    }

    const fetchStaggeredBlock = async() => {
        try {
            const res = await getOfflineStaggeredBlockAll()             
            setAllBlocks(res); 
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    //Sorting -->
    const AllowSortable =() =>{

        const config = (event, ui) =>{
            const ids = [];
            $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
            sortAndSave(ids);   
        }

        window.sortInit('#table-block tbody', config);    
    }

    const sortAndSave = (param)=> {
        if(param.length <= 0) return;      
        let blocks = [];        

        setAllBlocks( d => {
            const sortedBlocks = [];
            
            for (const [i,id] of Object.entries(param)) {
                let f = d.find( a => a.ID == id);
                if(!!f){
                    f.Index = parseInt(i)+1;                    
                    sortedBlocks.push(f);
                }
            }

            blocks = sortedBlocks;
            return d;
        });
                    
        if(blocks.length > 0) saveBlocks(blocks);                              
    }

    const clearAction = () => {       
        setNewBlock({Session : "Morning"});
        setIsEdit(false);
    }

    return(<>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                
                <div className="kt-portlet">
                    <div className="kt-portlet__body padding-t20 padding-b0">
                        <form className="kt-form sticky margin-b10 padding-t15 padding-b10 bg-white" id="addBlockForm" >                
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form" role="form">
                                <div className="form-group row mb-0">
                                    <label className="col-form-label col-sm-3 text-right"> Block </label>
                                    <div className="col-xl-3 col-sm-3">
                                        <div className="input-group timepicker">
                                            <input 
                                                id="txtBlock" name="txtBlock" type="text" className="form-control" 
                                                value={ newBlock.Block || '' }
                                                onChange={({target}) => dataHandler("Block", target.value)} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-clock-o"></i>
                                                </span>
                                            </div>
                                        </div>                                      
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right"> Session </label>
                                    <div className="col-xl-2 col-sm-2">
                                        <select  id="txtSession" className="form-control"  value={ newBlock.Session || '' } onChange={({target}) => dataHandler("Session", target.value)} >                                          
                                            {sessionType.map((s,i)=> ( <option key={i} value={s}> {s} </option> ) )}
                                        </select>                                                                                            
                                    </div>
                                    <div className="col-sm-3">
                                        {(!isEdit) ? (
                                            <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0`} onClick={postBlock} ><i className="la la-plus"></i>Add</button>
                                        ):(
                                            <div className="btn-group">
                                                <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={postBlock} > Update </button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>

                        <table id="table-block" className="table table-bordered table-hover margin-b30">
                            <thead className="light-grey-background">
                                <tr>
                                    <th> Block </th>
                                    <th> Session </th>
                                    <th>  </th>
                                </tr>
                            </thead>
                            <tbody>
                                {( allBlocks.length > 0 )?
                                    allBlocks.map((t,i) => {
                                        return (<tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                            <td>{t.Block}</td>
                                            <td>{t.Session}</td>
                                            <td className="text-center">
                                                <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit"
                                                 onClick={() => editHandler(t.ID)}><i className="flaticon2-edit"></i></button>
                                            </td>
                                        </tr>)
                                    }):
                                    (<tr><td colSpan={3}> 
                                        <div className="padding-10"> 
                                            <h5 className="text-center text-muted"> No Blocks..! </h5> 
                                        </div>
                                    </td></tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>    
    </>);
}
export default OfflineStaggeredBlock;