import { useEffect } from "react";

const UserDetails = (props) =>{

    const user = props.user;

    useEffect(() => {
        $('#user-detail-modal').modal({
            backdrop: 'static',
        });
        $('#user-detail-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
        $('#user-detail-modal').modal('toggle');
  
    })

    const copyText = (event, value) => {
        event.stopPropagation()
        navigator.clipboard.writeText(value);
        const btn =  $('.copyButton')
        btn.tooltip({
            trigger: 'click',
            placement: 'bottom',
            title:"Copied",
        });
        hideTooltip(btn);
          
        function hideTooltip(btn) {
            setTimeout(function() {
                btn.tooltip('hide');
            }, 500);
        }
    }

    return (
        <>
            <div className="modal fade" id="user-detail-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Member Details</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"                               
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body grey-background">
                            <div className="kt-portlet"> 
                                <div className="kt-portlet__body">
                                    <div className="kt-widget kt-widget--user-profile-3">
                                        <div className="kt-widget__top">
                                            <div className="kt-widget__media kt-hidden-">
                                                {user.Photo ? (
                                                        <img className='  img-profile-3' style={{ borderRadius: '0%' }} src={user.Photo} alt="photo"/>
                                                    ) : (
                                                        <div
                                                            className={` logoSize kt-widget__pic kt-widget__pic--${
                                                                user.MemberType === 'Primary'
                                                                ? 'success'
                                                                : 'brand'
                                                            } kt-font-${
                                                                user.MemberType === 'Primary'
                                                                ? 'success'
                                                                : 'brand'
                                                            } kt-font-boldest kt-hidden-`}>
                                                            { user.FirstName.slice(0, 1) +
                                                                user.LastName.slice(0, 1)}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="kt-widget__content">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-8">
                                                        <div title={`${user.FirstName} ${user.LastName}`} className="kt-widget__head">
                                                            <span className="kt-widget__username text-clip">
                                                                { user.FirstName + ' ' + user.MiddleName + ' ' + user.LastName}{' '}
                                                            </span>
                                                        </div>
                                                        <div className="kt-widget__subhead padding-t0">
                                                            <span className="padding-r10">
                                                                <i className="flaticon2-calendar-3"></i>
                                                                {user.Category} 
                                                            </span>
                                                            <span className="padding-r10">
                                                                <i className="flaticon2-user"></i>
                                                                {user.MembershipNo}
                                                            </span>
                                                            <br></br>
                                                            {user.Mobile && (
                                                                <span className="padding-r10">
                                                                <i className="flaticon2-phone"></i>
                                                                {user.Mobile}
                                                                </span>
                                                            )}
                                                            {user.Email && (
                                                                <span className="padding-r10">
                                                                <i className="flaticon2-new-email"></i>
                                                                {user.Email}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className={`block-badge mr-1 badge display-inline padding-lr20 padding-tb5 margin-l0 badge-${user.MemberType === 'Primary' ? 'success': (user.MemberType === 'Spouse' ? 'info' : 'warning')}`}>
                                                            <span>{user.MemberType}</span>
                                                        </div>
                                                    </div>
                                                </div>                                                      
                                            </div>
                                        </div>
                                        <div className={`kt-widget__bottom d-block`}>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-calendar-1"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Subscription Expires</span>                                                      
                                                            <span className="kt-widget__value">
                                                                {moment(user.SubscriptionExpiresOn).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-calendar-1"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Membership Expires</span>                                                      
                                                            <span className="kt-widget__value">
                                                                {moment(user.MembershipExpiresOn).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-avatar"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Gender</span>
                                                            <span className="kt-widget__value">
                                                                {user.Gender}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-app"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">App User</span>                                                      
                                                            <span className="kt-widget__value">
                                                                <span className={`kt-badge kt-badge--${user.IsAppUser == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{user.IsAppUser == 'Y' ? 'Yes' : 'No'}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-email"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Email Verified</span>                                                      
                                                            <span className="kt-widget__value">
                                                                <span className={`kt-badge kt-badge--${user.IsEmailVerified == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{user.IsEmailVerified == 'Y' ? 'Yes' : 'No'}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="la la-mobile-phone"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Mobile Verified</span>
                                                            <span className="kt-widget__value">
                                                                <span className={`kt-badge kt-badge--${user.IsMobileVerified == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{user.IsMobileVerified == 'Y' ? 'Yes' : 'No'}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon-calendar-3"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">Date Of Birth</span>                                                      
                                                            <span className="kt-widget__value">
                                                            {moment(user.DOB).format('DD/MM/YYYY') || '--'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon2-tag"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">BagTag</span>                                                      
                                                            <span className="kt-widget__value">
                                                                {user.BagTag || '--'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="kt-widget__item">
                                                        <div className="kt-widget__icon">
                                                            <i className="flaticon2-user-outline-symbol"></i>
                                                        </div>
                                                        <div className="kt-widget__details">
                                                            <span className="kt-widget__title">RFID</span>                                                      
                                                            <span className="kt-widget__value">
                                                                {user.RFID || '--'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>       
                                    </div>
                                </div>
                            </div>
                            {(user.MemberType == 'Primary' && (user.Address || user.Country || user.State || user.City || user.Pincode)) && (
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body my-auto padding-l0">
                                        <div className="kt-widget kt-widget--user-profile-3">
                                            <div className="kt-widget__top"  >
                                                <div  className="kt-widget__pic "  style={{ fontSize : '35px' }}>
                                                    <i className="flaticon-placeholder-3"></i>
                                                </div>
                                                <div style={{maxWidth:'85%'}} className="kt-widget__content">
                                                    <div className="row align-items-center">
                                                        <div className='col-12'>
                                                            <div className="kt-widget__subhead padding-t0 transaction">
                                                                <span title={user.Address} className="padding-r10">
                                                                    {user.Address}<br/>
                                                                    {user.Country || ''}
                                                                    {user.State ? (', ' + user.State) : ''}<br/>
                                                                    {user.City || ''}
                                                                    {user.Pincode ? (', ' + user.Pincode) : ''}
                                                                    <button className="btn btn-secondary copyButton" type='button' onClick={(e) => copyText(e, `${user.Address + ', '}${user.Country}${', ' + user.State}${', ' + user.City}${', ' + user.Pincode}`)} data-clipboard-text="1" >Copy</button>
                                                                </span>                                                      
                                                            </div>
                                                        </div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">
                                Close
                            </button>                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDetails;