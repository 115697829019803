import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Header from '../components/global/header';
import SideMenu from '../components/global/sideMenu';
import TeeBox from '../components/golfClub/teeBox';
import AdminMenu from '../components/settings/adminMenu';
import AdminUser from '../components/settings/adminUser';
import EmailTemplate from '../components/settings/emailTemplate';
import AdminRole from '../components/settings/adminRole';
import HolidayCalender from '../components/golfClub/holidayCalender';
import Users from '../components/users/users';
import StaggeredMaster from '../components/teeTime/staggeredMaster';
import StaggeredBlock from '../components/teeTime/staggeredBlock';
import StaggeredTemplate from '../components/teeTime/staggeredTemplate';
import ShotgunCalendar from '../components/teeTime/shotgunCalendar';
import ShotgunMaster from '../components/teeTime/shotgunMaster';
import ShotgunBlock from '../components/teeTime/shotgunBlock';
import ShotgunTemplate from '../components/teeTime/shotgunTemplate';
import TeeTimePublish from '../components/teeTime/teeTimePublish';
import CourseClosed from '../components/golfClub/courseClosed';
import Course from '../components/golfClub/course';
import CancellationSheet from '../components/teeTime/cancellationSheet';
import BookingSheet from '../components/teeTime/bookingSheet';
import TeeTimeBlocking from '../components/teeTime/teeTimeBlocking';

import OfflineStaggeredMaster from '../components/manualTee/offlineStaggeredMaster';
import OfflineStaggeredBlock from '../components/manualTee/offlineStaggeredBlock';
import OfflineStaggeredTemplate from '../components/manualTee/offlineStaggeredTemplate';

import OfflineShotgunMaster from '../components/manualTee/offlineShotgunMaster';
import OfflineShotgunBlock from '../components/manualTee/offlineShotgunBlock';
import OfflineShotgunTemplate from '../components/manualTee/offlineShotgunTemplate';
import OfflineShotgunCalendar from '../components/manualTee/offlineShotgunCalendar';
import OfflineTeePublish from '../components/manualTee/offlineTeePublish';

import TeeBooking from '../components/manualTee/teeBooking';
import OfflineBookingSheet from '../components/manualTee/offlineBookingSheet';
import OfflineCacellationSheet from '../components/manualTee/offlineCancellationSheet';
import DisplaySheet from '../components/manualTee/displaySheet';
import OfflineTeeTimeBlocking from '../components/manualTee/offlineTeeTimeBlocking';


const Admin = (props) => {

	const [headerTitle,                   setHeaderTitle]                = useState('');
    const [menuID,                        setMenuID]                     = useState(null);

    const toggleState = (title, menuID) => {
		setHeaderTitle(title);
        setMenuID(menuID);
	};

    return (  
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <SideMenu menuID={menuID} title={toggleState}/>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                
                <Header  onLogout={props.onAuthStateChange} title={headerTitle} /> 
                {menuID && <div style={{ paddingTop: '0px' }} id="kt_content" className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
                    <Switch>
                    {/* Settings */}
                        <Route path="/admin-user" exact>
                            <AdminUser 
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-role" exact>
                            <AdminRole 
                                title={toggleState} 
                            />
                        </Route>     
                        <Route path="/admin-menu" exact>
                            <AdminMenu 
                                title={toggleState}  
                            />
                        </Route>
                        <Route path="/email-template" exact>
                            <EmailTemplate 
                                title={toggleState} 
                            />
                        </Route>
                    {/* Settings End*/}

                    {/* Golf Club */}
                        <Route path="/holiday-calendar" exact>
                            <HolidayCalender 
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/course-closed" exact>
                            <CourseClosed
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/teebox" exact>
                            <TeeBox 
                                title={toggleState}  
                            />
                        </Route>
                        <Route path="/course" exact>
                            <Course
                                title={toggleState} 
                            />
                        </Route>
                    {/* Golf Club End*/}

                    {/* Tee Time */}
                        <Route path="/tee-sheet">
                            <BookingSheet 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/tee-cancel-sheet">
                            <CancellationSheet 
                                title={toggleState}
                            />
                        </Route>

                        <Route path="/tee-time-blocking">
                            <TeeTimeBlocking 
                                title={toggleState}
                            />
                        </Route>

                        <Route path="/staggered-master">
                            <StaggeredMaster title={toggleState}/>
                        </Route>

                        <Route path="/staggered-block">
                            <StaggeredBlock title={toggleState}/>
                        </Route>

                        <Route path="/staggered-template">
                            <StaggeredTemplate title={toggleState}/>
                        </Route>

                        <Route path="/shotgun-master">
                            <ShotgunMaster title={toggleState}/>
                        </Route>

                        <Route path="/shotgun-block">
                            <ShotgunBlock title={toggleState}/>
                        </Route>

                        <Route path="/shotgun-template">
                            <ShotgunTemplate title={toggleState}/>
                        </Route>

                        <Route path="/shotgun-calendar">
                            <ShotgunCalendar title={toggleState}/>
                        </Route>

                        <Route path="/teetime-publish">
                            <TeeTimePublish title={toggleState}/>
                        </Route>
                    {/* Tee Time End */}
                    {/* Users */}
                        <Route path="/user" exact>
                            <Users
                                title={toggleState} 
                            />
                        </Route>
                    {/* Users End*/}

                    {/* Manual Tee Time */}
                        <Route path="/offline-tee-master">
                            <OfflineStaggeredMaster title={toggleState}/>
                        </Route>

                        <Route path="/offline-cancel-sheet">
                            <OfflineCacellationSheet title={toggleState}/>
                        </Route>

                        <Route path="/offline-tee-time-blocking">
                            <OfflineTeeTimeBlocking title={toggleState}/>
                        </Route>

                        <Route path="/offline-tee-block">
                            <OfflineStaggeredBlock title={toggleState}/>
                        </Route>

                        <Route path="/offline-tee-template">
                            <OfflineStaggeredTemplate title={toggleState}/>
                        </Route>

                        <Route path="/offline-shotgun-master">
                            <OfflineShotgunMaster title={toggleState}/>
                        </Route>

                        <Route path="/offline-shotgun-block">
                            <OfflineShotgunBlock title={toggleState}/>
                        </Route>

                        <Route path="/offline-shotgun-template">
                            <OfflineShotgunTemplate title={toggleState}/>
                        </Route>

                        <Route path="/offline-shotgun-calendar">
                            <OfflineShotgunCalendar title={toggleState}/>
                        </Route>

                        <Route path="/offline-teetime-publish">
                            <OfflineTeePublish title={toggleState}/>
                        </Route>

                        <Route path="/offline-tee-sheet">
                            <OfflineBookingSheet title={toggleState}/>
                        </Route>

                        <Route path="/offline-booking">
                            <TeeBooking title={toggleState}/>
                        </Route>

                        <Route path="/display-tee-sheet">
                            <DisplaySheet title={toggleState}/>
                        </Route>

                        {/* Manual Tee Time End */}

                    {/* Default */}
                        <Redirect to={'/' + menuID} />
                        
                    </Switch>
                </div>}
            </div>
            
        </div>
    );
};

export default withRouter(Admin);