import { useEffect, useState } from "react";
import { getCourseAll, postCourse } from "../api/golfClubAPI";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";


const Course = (props) => {
    const [course,              setCourse]              = useState({});
    const [courseList,          setCourseList]          = useState([]);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    
    //<--- Cropper -->
    const [courseLogoBlob, setCourseLogoBlob] = useState(null);
    const [cropperData,     setCropperData]   = useState(null);

    useEffect(() => {
        props.title("Course", "course")
        fetchCourse();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setCourse({}), setCourseLogoBlob(null);
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [courseList]);
    

    const fetchCourse = async() =>{
        try {
            const courses = await getCourseAll()   
            isInit ? setIsInit(false) : $('#tblCourse').KTDatatable().destroy(); 
            setCourseList(courses);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {
        const dataTable = $('#tblCourse').KTDatatable({
            data: {
                saveState : false,
                source    : courseList,
                pageSize  : 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable  : true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Course',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.ShortName} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Email',
                    title: 'Email',
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblCourse .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setCourse(dataTable.dataSet[index]);  
                setRightSideDetails(true); 
            } 
        });
    }

    const updateCourseDetails = (field, value) => {   
        setCourse((d) => {
            return { ...d, [field]: value };
        });
    };

    const addCourseHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-Course-form');

        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtShortName: {
                    required: true,
                }         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtShortName: {
                    required: "Please enter short name"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
          
            let courseLogo = '';
            //Call upload API -->
            if(courseLogoBlob){
                const res = await uploadPublicFile(courseLogoBlob,'Content');
                courseLogo = res.fileName;            
            }else{
                if(course.Logo) courseLogo = course.Logo.split('/Content/')[1];
            }

            await postCourse({...course, Logo: courseLogo});
            helper.StopProcessing(btn);
            closeDrawer();
            fetchCourse();
            if (course.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Course updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Course added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {          
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setCourseLogoBlob(val),
            header : 'Upload Course Logo'
        })
    }
  
    
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Course..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Course</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblCourse" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Course Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-Course-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={course.Name || ''}
                                                            onChange={(event) => updateCourseDetails('Name', event.target.value)}  
                                                    />
                                                    </div>
                                            </div>     
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Short Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                            id="txtShortName" 
                                                            name="txtShortName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={course.ShortName || ''}
                                                            onChange={(event) => updateCourseDetails('ShortName', event.target.value)}
                                                    />
                                                    </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> Email </label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                            id="txtEmail" 
                                                            name="txtEmail" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={course.Email || ''}
                                                            onChange={(event) => updateCourseDetails('Email', event.target.value)}
                                                    />
                                                    </div>
                                            </div>  
                                            <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Logo </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!courseLogoBlob || course.Logo) &&(<>
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                                <div className="kt-avatar__holder border-none shadow-none" 
                                                                    style={{ backgroundImage : `url( ${courseLogoBlob ? URL.createObjectURL(courseLogoBlob) : course.Logo && (course.Logo) })` } }>    
                                                                </div>
                                                            </div>
                                                        </>) }

                                                        <p className="">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                               {(!!courseLogoBlob || course.Logo) ? 'Change Logo' : 'Upload Logo'}
                                                            </button>
                                                        </p>
                                                        <p className="">(200 W x 200 H pixels)</p>                                                       
                                                    </div>
                                            </div>
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions">
                                                        <div className="row">
                                                        <div className="col-lg-9 ml-lg-auto">
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addCourseHandler}>Save</button>
                                                            <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                   </div>
                               </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>           
            </div>

            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default Course;