import { useState } from "react";
import { useEffect } from "react"
import Select from 'react-select';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { getOfflineCancellationSheet } from "../api/offlineTeeAPI";

const OfflineCacellationSheet = (props) => {

    const [selectedFromDate,                setSelectedFromDate]            = useState(moment().toDate());
    const [selectedToDate,                  setSelectedToDate]              = useState(moment().toDate());
    const [start,                           setStart]                       = useState([{ value:  'Slot', label: 'Staggered Start'},{ value:  'Shotgun', label: 'Shotgun Start'}]);
    const [isInit,                          setIsInit]                      = useState(true);
    const [offlineCancellationSheet,        setOfflineCancellationSheet]    = useState([]);
    const [startType,                       setstartType]                   = useState(start[0].value);
    const [cancellationTable,               setCancellationTable]           = useState(null);

    useEffect(() => {
        props.title('Cancellation Sheet ','offline-cancel-sheet')
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            setSelectedFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
    },[])

    useEffect(() => {       
        if (!isInit) {
            fillTable();
        }
    }, [offlineCancellationSheet]);

    useEffect(() => {
        if(selectedFromDate && selectedToDate){
            fetchCancellationSheet(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),)
        }
    },[selectedFromDate, selectedToDate])

    useEffect(() => {
        if(startType && !isInit){
            $('#tblCancelSheet').KTDatatable().destroy(); 
            fillTable();
        }
    },[startType])

    const fetchCancellationSheet = async(fdate, tDate) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getOfflineCancellationSheet(fdate, tDate);
            // const response = []
            isInit ? setIsInit(false) : $('#tblCancelSheet').KTDatatable().destroy(); 
            setOfflineCancellationSheet(response.CancelSheet)
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    function exportSheet(type) {
        cancellationTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - Tee Cancellation Sheet - ${startType}` // pass in a new set of properties
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    }

    function fillTable() {

        // var startType = $('#ddlStart').val();
        
        var filteredList = offlineCancellationSheet.filter(i => i.TemplateType == startType);
        var tableFields = []

        Swal.close();

        $.each(filteredList, function (index, element) {

            if (element.Status != null) {
                element["Status"] = '<div class="badge badge-danger"><span>' + element["Status"] + '</span></div>';
            }

        });

        if (startType == 'Shotgun') {

            tableFields = [
                { field: "StartTime", title: "Time", width: 60},
                { field: "HoleNumber", title: "Hole No.", width: 60 },
            ]
        } else {

            tableFields = [{ field: "Slot", title: "Slot", width: 60 } ]
        }
        Config.Core.InitPopover();

        const dataTable = $('#tblCancelSheet').KTDatatable({
            
            data: {
                saveState: false,
                source: filteredList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'CancelDate',
                    title: "Cancel Dt",
                    width: 50,
                    template: function (row) {
                        return moment(row.CancelDate).format('DD-MM-YYYY');
                    }
                },
                {
                    field: 'BookDate',
                    title: "Book Dt ",
                    width: 50,
                    template: function (row) {
                        return moment(row.BookDate).format('DD-MM-YYYY');
                    }
                },
                ...tableFields,
 
                { field: "Hole", title: "Holes", width: 40, },
                { field: "Player1", title: "Player 1", },
                { field: "Player2", title: "Player 2", },
                { field: "Player3", title: "Player 3", },
                { field: "Player4", title: "Player 4", },
                {
                    field: 'Status',
                    title: 'Status',
                    template: function (row) {
                        var text = row.Status;
                        if (row.Status === null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return text;
                        }
                    }
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {
                        if (row.Comment) {
                            return `<center>                                              
                                        <button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>
                                    </center>`;
                        } else {
                            return '';
                        }
                    }
                }
            ],
            rows: {
                autoHide: false,
            }
            
        });
        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        // export to excel
        const CancellationTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Tee Cancellation Sheet`,
        });
        setCancellationTable(CancellationTable);
        $('.tableexport-caption').addClass('hidden');
        
    }
     return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">From Date</label>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtFromDate" 
                                                name="txtFromDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">To Date</label>
                                    <div className="form-group  col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtToDate" 
                                                name="txtToDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="col-sm-1">
                                        {/* <button id="btnSearch" type="button" className="btn btn-label-brand d-inline-block"><i className="la la-search"></i></button> */}
                                    </div>
                                    <div  className="form-group col-sm-2 pnlStart ">
                                        <div >
                                            <Select 
                                                id="ddlStart" 
                                                name="ddlStart "
                                                className="multi-custom "
                                                defaultValue={ start.find((m) => m.value[0] )  || ''}
                                                onChange={(event) => setstartType(event.value)}
                                                options={start}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Tee Time..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}
                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblCancelSheet" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
     )
}

export default OfflineCacellationSheet;