import { useState, useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getShotgunBlockAll, postShotgunBlock } from "../api/teeTimeAPI"

const sessionType = ["Morning","Afternoon"];

const ShotgunBlock = (props) => {

    const [allBlocks,    setAllBlocks]      = useState([]);
    const [newBlock,     setNewBlock]       = useState({ Session : "Morning" });
    const [isEdit,       setIsEdit]         = useState(false);

    useEffect(() => {
        props.title("Shotgun Block", "shotgun-block");       
        fetchShotgunBlock();

        //Sorting Function -->
        AllowSortable();

        //Init Timepicker -->
        $('#txtStartTime').timepicker({ minuteStep: 10, defaultTime: '05:00 AM'});
        $('#txtStartTime').on('change', ({target}) => dataHandler("StartTime", target.value) );
    }, []);

    const dataHandler = (field, value) => setNewBlock(d => ({...d, [field]: value}));

    const editHandler = (id) => {      
        setIsEdit(true);
        const d = allBlocks.find( a => a.ID == id);
        setNewBlock(d);

        //highlight update row -->
        $(`tr[data-id =${id}]`).addClass("bg-lightgrey");
    }

    const fetchShotgunBlock = async() => {
        try {
            const res = await getShotgunBlockAll()             
            setAllBlocks(res); 
        } catch (error) {
            swal.fire({ icon : 'error', titleText : error.message })
        }
    }

    const postBlock = async() => {      
        const btn  = (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addBlockForm');
     
        form.validate({
            rules: { txtBlock: { required: true }, txtStartTime: { required: true }},
            messages: { 
                txtBlock : { required: "Please enter block name" },
                txtStartTime : { required: "Please select time" },
            }
        });

        if (!form.valid()) return;
        
        const isExist = allBlocks.find(s => s.StartTime == newBlock.StartTime && s.ID !== newBlock.ID);

        //Validation Goes Here -->
        if (!!isExist){
            swal.fire({ icon: "warning", titleText: 'Oops..', text: 'Start Time already exist!', buttonsStyling: false, confirmButtonClass: "btn btn-brand"});
            return;
        } 

        helper.StartProcessing(btn);
        try {                    
            if(!isEdit) newBlock.Index = allBlocks.length + 1;                                   
                                 
            saveBlocks([newBlock]);            
            
            helper.StopProcessing(btn);                  
            
            if (newBlock.ID) {            
                swal.fire({ icon: 'success', toast: true, timer: 2500, position: 'bottom-end', showConfirmButton: false,
                    titleText: 'Block updated successfully!',
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({ icon: 'success', timer: 2500, showConfirmButton: false,
                    titleText: 'Block added successfully!',
                    onOpen: () => swal.hideLoading()    
                });
            }
            
            //Clear State ->
            clearAction();
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message, buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;
        }
    }

    const saveBlocks = async(blocks) => {
        if(blocks.length <= 0) return;

        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
            
            const res = await postShotgunBlock(blocks);
            setAllBlocks(res);          
            swal.close();
        } catch (err) {
            swal.close();
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message,
                buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
            });
            return;  
        }
    }

     //Sorting -->
    const AllowSortable =() =>{
        const config = (event, ui) =>{
            const ids = [];
            $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
            sortAndSave(ids);   
        }

        window.sortInit('#table-block tbody', config);    
    }

    const sortAndSave = (param)=> {
        if(param.length <= 0) return;      
        let blocks = [];        

        setAllBlocks( d => {
            const sortedBlocks = [];
            
            for (const [i,id] of Object.entries(param)) {
                let f = d.find( a => a.ID == id);
                if(!!f){
                    f.Index = parseInt(i)+1;                    
                    sortedBlocks.push(f);
                }
            }

            blocks = sortedBlocks;
            return d;
        });
                    
        if(blocks.length > 0) saveBlocks(blocks);                              
    }

    const clearAction = () => {       
        setIsEdit(false);
        setNewBlock({Session : "Morning"});

        $("#table-block tbody tr").removeClass("bg-lightgrey");
    }
    
    return(<>
        <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                
                <div className="kt-portlet">
                    <div className="kt-portlet__body padding-t30 padding-b0">
                        <form className="kt-form" id="addBlockForm" >                
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form" role="form">
                                <div className="row justify-content-center">                                   
                                    <div className="form-group col-sm-3 d-flex">
                                        <label className="col-form-label text-right margin-r10"> Block </label>                                    
                                        <div className="input-group timepicker">
                                            <input 
                                                id="txtBlock" name="txtBlock" type="text" className="form-control" placeholder="Block name"
                                                value={ newBlock.Block || '' }
                                                onChange={({target}) => dataHandler("Block", target.value)} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text"> <i className="la la-clock-o"></i> </span>
                                            </div>
                                        </div>                                        
                                    </div>
                                              
                                    <label className="col-form-label col-sm-1 text-right"> Session </label>
                                    <div className="form-group col-sm-2">
                                        <select  id="txtSession" className="form-control"  value={ newBlock.Session || '' } onChange={({target}) => dataHandler("Session", target.value)} >                                        
                                            {sessionType.map((s,i)=> {
                                                return( <option key={i} value={s}> {s} </option> );
                                            })}
                                        </select>                                                                                            
                                    </div>

                                    <div className=" form-group col-sm-4 d-flex">
                                        <label className="col-form-label text-right width-55 margin-lr20"> StartTime </label>
                                        <div className="input-group timepicker width-180  margin-r20">
                                            <input id="txtStartTime" name="txtStartTime" type="text" className="form-control" 
                                                readOnly={true} placeholder="Select time" 
                                                value={ newBlock.StartTime || '' }                                               
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text"> <i className="la la-clock-o"></i> </span>
                                            </div>
                                        </div>

                                        {(!isEdit) ? (
                                            <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0 height-38`} onClick={postBlock} ><i className="la la-plus"></i>Add</button>
                                        ):(
                                            <div className="btn-group height-38">
                                                <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={postBlock} > Update </button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>

                        <table id="table-block" className="table table-bordered table-hover margin-b30">
                            <thead className="light-grey-background">
                                <tr>
                                    <th> Block </th>
                                    <th> Session </th>
                                    <th> StartTime </th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {( allBlocks.length > 0 )?
                                    allBlocks.map((t,i) => {
                                        return (<tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                            <td>{t.Block}</td>
                                            <td>{t.Session}</td>
                                            <td>{t.StartTime}</td>
                                            <td className="text-center">
                                                <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit"
                                                 onClick={() => editHandler(t.ID)}><i className="flaticon2-edit"></i></button>
                                            </td>
                                        </tr>)
                                    }):
                                    (<tr><td colSpan={4}> 
                                        <div className="padding-10"> 
                                            <h5 className="text-center text-muted"> No Blocks Available..! </h5> 
                                        </div>
                                    </td></tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </>)
}

export default ShotgunBlock;