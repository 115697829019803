import { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import { getOfflinePublishPageData, getOfflinePublishMasterData, postOfflineTeeTimePublish } from "../api/offlineTeeAPI";
import TeeTimePublishTable from "../teeTime/teeTimePublishTable";

const OfflineTeePublish = (props) => {
    
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isInit,    setIsInit]      = useState(true);
    const [isCreated, setIsCreated]   = useState(false);
    
    const [pageData, setPageData]   = useState();
    const [dayData,   setDayData]   = useState();
            
    const [publishMaster, setPublishMaster] = useState({});
    const [publishDays,   setPublishDays]   = useState([]);

    
    useEffect(() => {
        props.title("Tee Time Publish", 'offline-teetime-publish');       
        fetchTeeTimePublish();
    }, []);

    useEffect(() => {       
        if (!isInit) fillDataTable();            
    }, [pageData]);
    
    useEffect(() => {  
        if(openDrawer){
            Config.Core.OpenDrawer() 
        }else{
            setPublishMaster({});
            resetTable();      
        }       
    }, [openDrawer]);
    
    useEffect(() => {
        //Sets  Publish Day for Update -->
        if(!!dayData){
            
            if(dayData.publishDays.length > 0){
                const newPublishDays = dayData.publishDays.map((dy => {
                   const w = dayData.publishWeek.find( f => f.ID === dy.PublishWeekID);
                   return ({...dy, MasterIndex : w.Index});
                }))
                
                setPublishDays(newPublishDays);  
            }
        }
    },[dayData])
        
    const fetchTeeTimePublish = async() => {
        try {
            const response = await getOfflinePublishPageData();
                     
            isInit ? setIsInit(false) : $('#tblMaster').KTDatatable().destroy(); 
            setPageData(response);         
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message });
        }
    }
    
    const postData = async(data) => {
       
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            //Post and Refetch -->            
            await postOfflineTeeTimePublish(data);
            
            await fetchTeeTimePublish();
            closeDrawer();

            if(!publishMaster.ID){
                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master added successfully', 
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master updated successfully', 
                    onOpen: () => swal.hideLoading()                                     
                });
            }
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    } 

    //#region  Helpers -->

        const createDragable = ()=> {      
            let dragholder = [];
            
            //dragable-days Holder -->    
            $('.day-holder').each((i, element) => dragholder.push(element));

            //dragable-days -->
            dragholder.push(document.getElementById('days-holder'));

            window.dragInit(dragholder, onDragConfig);
        }
    
        const saveData = ()=> {   
            let isValid       = true;   
            const publishWeek = [];            
            
            //Validation Check -->
            if (publishDays.length !== 7) {
                swal.fire({ icon: 'warning', title: 'All days are not published', timer: 2000 });
                return;
            }
            
            $('[data-rowindex]').each((index, row) => {
                let masterIndex   = parseInt(row.getAttribute('data-rowindex'))
                let currentDay    = $(row).children('th').data('day');
                let MorningTime   = $(`#txtSlot1-${currentDay}`).val();
                let AfternoonTime = $(`#txtSlot2-${currentDay}`).val();
                
                const daysCount = publishDays.filter( d => d.MasterIndex === masterIndex).length;

                //time-validation -->
                if((MorningTime ==='' || AfternoonTime === '') && daysCount != 0 ){
                    swal.fire({ icon: 'warning', title: 'Start time not selected', timer: 2500});                   
                    isValid = false;
                    return false;                    
                }

                const daymaster = { MorningTime, AfternoonTime, Day: currentDay, DayCount: daysCount, Index: masterIndex };
                                                            
                publishWeek.push(daymaster);
            });
            
            //Post TeeTimePublish -->          
            if (!isValid) return;
            postData({publishMaster, publishWeek, publishDays});
        }
        
        //Input & onChange Handler --->
        const dataHandler = (field, value)=> {
            const { LatestMaster, MaxOpenedDays, IsPrevMaster, ValidStartDate, ValidSkipDays } = pageData;                
            
            setPublishMaster(d => ({...d, [field]: value}));
                        
            if((field != "EndDate")) resetTable();  
            
            if(!publishMaster.ID && !!LatestMaster && (field == 'StartDate') && (MaxOpenedDays != 0) ){              
                const StartDateDiff = Math.abs(moment(ValidStartDate, 'DD/MM/YYYY').diff(moment(value , 'YYYY-MM-DD'), 'days'));
                const newSkipDays   = Math.abs(ValidSkipDays - StartDateDiff);
                              
                if((ValidSkipDays != 0) && StartDateDiff <= (MaxOpenedDays - (IsPrevMaster ? 0 : 1))) 
                    setPublishMaster(d => ({...d, ["SkipDays"]: newSkipDays}))                         
            }
        };

        const onDayDrag = (param)=> {       
            setPublishDays( d => {
                const n =  d.filter(c => c.Day !== param.Day);
                return [...n, param];
            })
        } 

        const fillDataTable = ()=> {
            const dataTable = $('#tblMaster').KTDatatable({
                data     : { saveState: false, source : pageData.PublishMasters, pageSize : 20 },
                layout   : { scroll: true, height: $(window).height() - 250, footer: false },
                sortable : true, pagination: true,
                search   : { input: $('#generalSearch')},
                columns  : [               
                    {
                        field: 'StartDate', title: 'Start Date', width: 300,
                        template: (row) => moment(row.StartDate).format('DD-MMM-YYYY'),  
                    },
                    {
                        field: 'EndDate', title: 'End Date',   width: 300,
                        template: (row) => moment(row.EndDate).format('DD-MMM-YYYY'),                                    
                    },
                    {  field: 'SkipDays',  title: 'Skip Days', width: 250 },
                ]
            });
        
            dataTable.on('click', 'tr', function () {
                const index         = ($("tr").index(this) - 1);
                const currentMaster = dataTable.dataSet[index]
                if(!!currentMaster) onOpenDrawer(currentMaster);                
            });    
        }
    
        const onOpenDrawer = async(selectedMaster)=> { 
            if(!pageData) return;

            const { PublishMasters, LatestMaster, MaxOpenedDays, ValidStartDate, ValidSkipDays } = pageData;

            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
            try {
                setOpenDrawer(true);
            
                //Set Current Master -->
                if(!!selectedMaster){
                    setPublishMaster(selectedMaster);
                    //get Selected Master Week And Days -->
                    const masterData = await getOfflinePublishMasterData(selectedMaster.ID);               
                    setDayData(masterData);  
                    createTable(selectedMaster);                
                }
    
                //Init Date picker -->
                setTimeout(() => {
                    $('.date-input').datepicker({ format: 'dd-mm-yyyy', minDate: new Date()});                
                    $('#txtStartDate').on('change', ({target}) => dataHandler('StartDate', moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));  
                    $('#txtEndDate').on('change', ({target}) => dataHandler('EndDate', moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));          
                }, 200); 
                
                //StartTime & Skipdays AS per previously opened master if Exist -->
                if((PublishMasters.length != 0) && !!LatestMaster && !selectedMaster) {

                    if(MaxOpenedDays != 0) dataHandler("SkipDays", ValidSkipDays);
                    else dataHandler("SkipDays", 1);                                      
                    
                    dataHandler("StartDate", moment(ValidStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                }
                    
                swal.close();
            } catch (error) {
                swal.close();
                swal.fire({ icon :'error', titleText : error.message });
            }                           
        }
        
        const createTable = (master)=> {
            if(!master) return;
                                                    
            const form = $('#addMasterForm');
            form.validate({
                rules: { txtStartDate: { required: true }, txtEndDate  : { required: true }, txtskipdays : { required: true } },
                messages: {
                    txtStartDate: { required: "Please select Start Date"},
                    txtEndDate  : { required: "Please select End Date"},
                    txtskipdays : { required: "Please enter skip days" },
                }
            });
    
            if (!form.valid()) return;
    
            const isValid = validateMaster(master);
            if (!isValid) return;
                                    
            setIsCreated(true);         
                      
            if (!!master.ID && !master.IsEdit) {
                $('#txtStartDate').attr('disabled', true);
                $('#txtskipdays').attr('disabled', true);
            }

            //init Drag & Slotpicker -->
            setTimeout(() => {                
                $('.slot-picker').timepicker({ minuteStep: 10, defaultTime: ''});  
                createDragable();
                
                //if IsEdit is false don't allow to drag days -->
                if(!!master.ID && !master.IsEdit) window.clearDrag();
            }, 300);
        }

        const validateMaster = (master)=> {
            const { PublishMasters, ValidStartDate } = pageData;
            
            const { StartDate, EndDate, SkipDays } = master;

            let isValidMaster = true;
            let msg = '';
            
            if (moment(StartDate).isAfter(EndDate)) {
                msg = 'Invalid Dates';
                isValidMaster = false;
            }

            if (SkipDays < 1) {
                msg = 'Skip days cannot be less 1';
                isValidMaster = false;
            }
           
            //Check if SkipDays < Previously Opened Days --> 
            const targetDate    = moment(StartDate).add(SkipDays, 'days');
            var inValidSkipDays = moment(targetDate).isBefore(moment(ValidStartDate, "DD/MM/YYYY"));
         
            if ((!master.ID) && (PublishMasters.length != 0) && inValidSkipDays) {                
                msg = 'Skip days cannot be less than days opened by previous master';
                isValidMaster = false;
            }

            if (!isValidMaster) swal.fire({  icon: 'warning',  titleText: msg,  timer: 2500,  showConfirmButton: false });
            
            return isValidMaster;
        }

        const onDragConfig = (el, target, source, sibling)=> {
            let parentIndex  = $(target).parent().attr('data-rowIndex');
            let currentIndex = $(el).data('index');
            let currentDay   = $(el).data('val');
            let isValid      = $(target).find('div').length < 1;
                
            if ($(target).attr('id') == 'days-holder') isValid = false;
        
            if (isValid) {
                //Check Below Rows for Lower Index
                for (let i = (parseInt(parentIndex) + 1); i < 8; i++) {
                    var row = $(`[data-rowindex = ${i}]`);
                    row.find('.dragable-day').each((index, element) => {
                        if ($(element).data('index') < currentIndex) {    
                            swal.fire({ toast: true, icon: 'warning', position: 'bottom-end', showConfirmButton: false, timer: 1000,
                                title: 'Previous day is not opened..!',
                            });
        
                            isValid = false;
                        }
                    });
                }

                //Check Above Rows for Higher Index
                for (let i = 1; i < parentIndex; i++) {
                    var row = $(`[data-rowindex = ${i}]`);
                    row.find('.dragable-day').each((index, element) => {
                        if ($(element).data('index') > currentIndex) {
                            swal.fire({ toast: true, icon: 'warning', timer: 1000, position: 'bottom-end', showConfirmButton: false,
                                title: 'Next day is already opened..!',
                            });    

                            isValid = false;
                        }
                    });
                }

                //call day setter -->         
                onDayDrag({ Day: currentDay, Index: currentIndex, MasterIndex: parseInt(parentIndex) });            
            } 

            return isValid;
        };

        const resetTable = ()=> {
            setIsCreated(false);
            setDayData();
            setPublishDays([]);
            window.clearDrag();
        } 

        const closeDrawer = ()=> {
            Config.Core.CloseDrawer();
            setTimeout(() => setOpenDrawer(false), 200);           
        }  

    //#endregion

    return(<>
         <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Tee Time Publish..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => onOpenDrawer()} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i> New Master </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblMaster" className="kt-datatable table-striped clickable"></div>

                        {openDrawer && (                    
                            <RightSideDetails title="Calender Details" onCloseDrawer={() => closeDrawer()}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">  

                                                <form className="kt-form" id="addMasterForm">
                                                    <div className="row margin-b15">
                                                        <div className="col-4 padding-0 padding-r5">
                                                            <div className="form-group mb-0  row">
                                                                <label className="col-form-label col-sm-4">Start Date <span className="red">*</span></label>
                                                                <div className="col-sm-8">
                                                                    <div className="input-group date">
                                                                        <input id="txtStartDate" name="txtStartDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select date"
                                                                            value={publishMaster.StartDate ? moment(publishMaster.StartDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">
                                                                                <i className="la la-calendar-check-o"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 padding-0  padding-r5">
                                                            <div className="form-group mb-0 row">
                                                                <label className="col-form-label col-sm-4">End Date <span className="red">*</span></label>
                                                                <div className="col-sm-8">
                                                                    <div className="input-group date">
                                                                        <input id="txtEndDate" name="txtEndDate" type="text" className="form-control date-input " readOnly={true} placeholder="Select date"
                                                                            value={publishMaster.EndDate ? moment(publishMaster.EndDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">
                                                                                <i className="la la-calendar-check-o"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3  padding-r5 ">
                                                            <div className="form-group mb-0 row">
                                                                <label className="col-form-label col-sm-7"> Advance opening <span className="red">*</span></label>
                                                                <div className="col-sm-5">
                                                                    <input id="txtskipdays" name="txtskipdays" type="text" className="form-control"
                                                                        value = {publishMaster.SkipDays || ''}
                                                                        onChange={({target}) => dataHandler("SkipDays", target.value)}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-1 padding-t3">
                                                            {!isCreated && (
                                                                <button id="btnCreate" type="button" className="btn btn-sm btn-primary m-0"
                                                                    onClick={() => createTable(publishMaster)}
                                                                > Create </button>
                                                            )}
                                                        </div>
                                                    </div>                                                                                                                                                                                           
                                                </form>
                                                
                                                {/* Table holder */}
                                                {isCreated && (<>
                                                    <TeeTimePublishTable publishMaster= {publishMaster} dayData={dayData} />
                                                </>)}                                      
                                            </div>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                            <div className="col-lg-9 ml-lg-auto">
                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={saveData}> Save </button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}> Cancel </button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default OfflineTeePublish;
