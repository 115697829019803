import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAdminMenuAll, postAdminMenu } from "../api/adminAPI";
import Select from 'react-select';

const AdminMenu = (props) =>{

    const [menuList,            setMenuList]            = useState([]);
    const [menu,                setMenu]                = useState({Active: 'Y'});
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);

    useEffect(() => {
        props.title("Admin Menu", "admin-menu")   
        fetchAdminMenu();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setMenu({Active: 'Y'});
    }, [rightSideDetails]);

    useEffect(() => {    
        if (!isInit) {
            fillDataTable();
        }
    }, [menuList]);

    const fetchAdminMenu = async() =>{
        try {
            const menues = await getAdminMenuAll()
            isInit ? setIsInit(false) : $('#tblAdminMenu').KTDatatable().destroy(); 
            setMenuList(menues);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fillDataTable = () =>{
        const dataTable = $('#tblAdminMenu').KTDatatable({
            data: {
                saveState: false,
                source: menuList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'MenuID',
                    title: 'ID',
                    width: 20,
                    type: 'number',
                    textAlign: 'center',
                },
                {
                    field: 'Name',
                    title: 'Name',
                },
                {
                    field: 'Level',
                    title: 'Level',
                    type: 'number',
                    textAlign: 'center',
                },
                {
                    field: 'Index',
                    title: 'Index',
                    type: 'number',
                    textAlign: 'center',
                },
                {
                    field: 'Active',
                    title: 'Active',
                    textAlign: 'center',
                },
            ]
        });
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setMenu(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            }                
        });
    }

    const updateMenuDetails = (field, value) => {
        setMenu((d) => {
            return { ...d, [field]: value };
        });
    };

    const addMenuHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-menu-form');
        
        form.validate({
            rules: {
                // ddlParentMenu: {
                //     required: true
                // },
                txtName: {
                    required: true
                },
                txtLevel: {
                    required: true,
                    number: true
                },
                txtIndex: {
                    required: true,
                    number: true
                }           
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtLevel: {
                    required: "Please enter level"
                },
                txtIndex: {
                    required: "Please enter index"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postAdminMenu({...menu, ParentMenuID: menu.ParentMenuID ? menu.ParentMenuID : 0 });
            helper.StopProcessing(btn);
            closeDrawer();
            fetchAdminMenu();
            if (menu.MenuID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Menu updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Menu added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return(
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Menu..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Menu</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblAdminMenu" className="kt-datatable table-striped clickable"></div>
                       {rightSideDetails && <RightSideDetails title="Menu Details" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                <form className="kt-form" id="add-menu-form">
                                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Parent Menu</label>
                                            <div className="col-xl-3 col-lg-4">
                                                <Select
                                                    value={(menuList && menuList.length > 0 && menu.ParentMenuID) && {label: menuList.find(m => m.MenuID == menu.ParentMenuID).Name, value: menu.ParentMenuID}}
                                                    onChange={(event) => updateMenuDetails('ParentMenuID', event.value)}
                                                    name="ddlParentMenu"
                                                    placeholder="Select Parent Menu"
                                                    id="ddlParentMenu"
                                                    className="ddlParentMenu"
                                                    options={menuList.filter(f => f.ParentMenuID == 0).map(c => ({
                                                        label: c.Name,
                                                        value: c.MenuID
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                    id="txtName" 
                                                    name="txtName" 
                                                    type="text" 
                                                    className="form-control"
                                                    value={menu.Name || ''}
                                                    onChange={(event) => updateMenuDetails('Name', event.target.value)}               
                                                    />						
                                            </div>
                                        </div>                                           
                                        <div className="form-group row">
                                                <label className="col-form-label col-lg-3">URL</label>
                                                <div className="col-xl-6 col-lg-8">
                                                <input
                                                    id="txtUrl"
                                                    name="txtUrl"
                                                    type="text" 
                                                    className="form-control"
                                                    value={menu.Url || ''}
                                                    onChange={(event) => updateMenuDetails('Url', event.target.value)}      
                                                    />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Level <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                <input
                                                    id="txtLevel"
                                                    name="txtLevel"
                                                    type="number"
                                                    className="form-control"
                                                    value={menu.Level || menu.Level == 0 ? menu.Level : ''}
                                                    onChange={(event) => updateMenuDetails('Level', event.target.value)} 
                                                    />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Index <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                <input 
                                                    id="txtIndex" 
                                                    name="txtIndex" 
                                                    type="number" 
                                                    className="form-control"
                                                    value={menu.Index || menu.Index == 0 ? menu.Index : ''}
                                                    onChange={(event) => updateMenuDetails('Index', event.target.value)} 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Icon</label>
                                                <div className="col-xl-6 col-lg-8">
                                                <textarea 
                                                    id="txtIcon" 
                                                    name="txtIcon" 
                                                    className="form-control" 
                                                    rows="3"
                                                    value={menu.Icon || ''}
                                                    onChange={(event) => updateMenuDetails('Icon', event.target.value)} 

                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Active</label>
                                                <div className="col-xl-6 col-lg-8">
                                                <span className="kt-switch kt-switch--icon">
                                                    <label>
                                                        <input 
                                                            id="chkActive" 
                                                            name="chkActive" 
                                                            type="checkbox" 
                                                            defaultChecked={menu.Active == 'N' ? '' : 'checked'}
                                                            onChange={(event) => updateMenuDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                            />
                                                        <span></span>
                                                    </label>
                                                </span>
                                            </div>
                                        </div>                                           
                                    </div>
                                </form>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-lg-9 ml-lg-auto">
                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addMenuHandler}>Save</button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>  
                </div>
            </div>
        </>
    )
}

export default AdminMenu