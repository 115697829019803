import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getAdminMenuUrl                                    = '/api/admin/admin/getAdminMenu';
const postAdminRoleUrl                                   = '/api/admin/admin/postAdminRole';
const getAdminMenuForRoleUrl                             = (id) => `/api/admin/admin/getAdminMenuForRole/${id}`;
const getAdminMenuAllUrl                                 = '/api/admin/admin/getAdminMenuAll';
const postAdminMenuUrl                                   = '/api/admin/admin/postAdminMenu';
const getAdminUserUrl                                    = '/api/admin/admin/getAdminUserAll';
const postAdminUserUrl                                   = (email) => `/api/admin/admin/postAdminUser/${email}`;


export const getAdminMenu = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getAdminMenuUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAdminMenuRole = async (ID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    const response = await axiosGet(
        getAdminMenuForRoleUrl(ID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postAdminRole = async (isChecked, menuID, userID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosPost(
        postAdminRoleUrl,
        { isChecked, menuID, userID },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAdminMenuAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getAdminMenuAllUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postAdminMenu = async (menuDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosPost(
        postAdminMenuUrl,
        { menuDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAdminUser = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAdminUserUrl,
        { headers: { auth_token: `bearer ${token}` } }
        );
        return response;
};

export const postAdminUser = async (userDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAdminUserUrl(userDetails.Email),
        { userDetails },
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

