import { useState, useEffect } from "react"
import Select from "react-select";
import swal from 'sweetalert2';
import {getLatestOfflineTeeTimeTemplate, getOfflineTeeTimeBookData, postOfflineBooking} from "../api/offlineTeeAPI";
import ScannerModal from "./scannerModal";

const TeeBooking = (props) => {

    //send Serverdate -->
    const [pageData,        setPageData]        = useState([]);
    const [bookData,        setBookData ]       = useState({});
    
    const [selectedUser,    setSelectedUser]    = useState();
    const [userList,        setUserList]        = useState([]);

    const [StartType,       setStartType]       = useState();
    
    const [showScanner,     setShowScanner]     = useState(false);
    const [holiday,         setHoliday]         = useState();

    useEffect(() => {
        props.title("First Come Basis Booking", 'offline-booking'); 
        fetchBookingData();
    }, []);
 
      
    const fetchBookingData = async() => {      
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {            
            const res = await getOfflineTeeTimeBookData()  
            const day = moment(bookData.BookDate).format('dddd');
            const isWeekend = (day == 'Saturday') || (day == 'Sunday') || res.isHoliday;
            setHoliday(isWeekend)                    
            setPageData(res);
            setStartType(res.StartType);
            
            let newBook = {BookDate : moment(res.ServerDate).format('YYYY-MM-DD')};
            if(res.StartType == 'Shotgun'){
                newBook.TemplateID = res?.AvailableShotgunTemplate?.TemplateID;
                newBook.StartTime  = res?.AvailableShotgunTemplate?.StartTime;   
                newBook.HoleNumber = res?.AvailableShotgunTemplate?.HoleNumber;
                newBook.Session    = res?.AvailableShotgunTemplate?.Session;                
            }  
            
            setBookData(newBook)        
            swal.close();
        } catch (err) {
            swal.close();
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const getAvailableSlot = async(t) => {
        if(!t) return;
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });

        try {
            const data = await getLatestOfflineTeeTimeTemplate(t);           

            if(data.AvailableSlot){                                                
                setBookData(d => ({...d, 
                    TemplateID : data?.AvailableSlot?.TemplateID, 
                    Session    : data?.AvailableSlot?.Session, 
                    Slot       : data?.AvailableSlot?.Slot
                }));         
            }else{                
                setBookData(d => {
                    delete d.Slot
                    delete d.TemplateID
                    return {...d};
                })
            }
            swal.close();
        } catch (err) {
            swal.close();
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const postBooking = async()=>{    
        if(userList.length < 2)  return swal.fire({ icon:'warning', titleText:'Minimum 2 Members Required' });
        if(!bookData.TemplateID) return swal.fire({ icon:'warning', titleText:'No Slot Available' });
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });    
        try {           
            const response = await postOfflineBooking(bookData, userList);        

            if(response.Message == 'fail'){
                return swal.fire({ icon: 'warning', titleText: response.LongMessage });             
            }
                
            swal.fire({ icon: 'success', titleText: 'Tee Time Booked Successfully'});   

            setTimeout(() => resetData() ,100); //reset         
        } catch (err) {
            swal.close();
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const inputHandler = (key, value)=> setBookData(d =>({...d,[key]:value}));

    const onTeeBoxChange =(t)=> {
        inputHandler('TeeBox', parseInt(t))
        getAvailableSlot(t);
    }
    
    const addBookingUser=()=>{
        if(!selectedUser) return;
        const { value:newUser } = selectedUser;

        if(selectedUser.value.MembershipCategory == 'Mid-Week Member' && holiday){
            swal.fire({
                icon: 'warning',
                titleText: 'Booking not available for Mid-Week Members',
                showConfirmButton: true,
            });
            return
        }
        if(userList.length > 3) return swal.fire({ icon: 'warning', titleText: 'Maximum 4 Players Allowed' });
        
        const isExist = userList.find( u => u.UserID == newUser.UserID);
        if(isExist) return swal.fire({ icon: 'warning', titleText: 'Member Already Added' });
        
        setUserList(d => ([...d, { UserID: newUser.UserID, UserType: 'User', FirstName: newUser.FirstName, MiddleName: newUser.MiddleName, LastName: newUser.LastName, MembershipNo : newUser.MembershipNo }]));
        setSelectedUser(); //Clear UserDD
    }

    const deleteUser  = (userId) => {
        const newList = userList.filter( u => u.UserID != userId);        
        setUserList(newList);
    }
        
    const resetData = () => {
        // setBookData({BookDate : moment(pageData.ServerDate).format('YYYY-MM-DD')});
        setSelectedUser();
        setUserList([]);       
        fetchBookingData();
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        {/* <div className="kt-portlet__head kt-portlet__head--lg padding-l0">                           
                        </div> */}
                        <div className="kt-portlet__body kt-portlet__body--fit padding-10 padding-t40">
                           
                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="form-group row">                                    
                                    <label className="col-form-label col-lg-3"> Booking Date </label>
                                    <div className="col-xl-6 col-lg-8">
                                        <input 
                                            id="lblDate" name="lblDate" type="text" className="form-control" disabled="disabled" 
                                            value={bookData?.BookDate || moment().format('DD-MM-YYYY')} 
                                        />
                                    </div>
                                </div>

                                {(StartType == 'Shotgun') ? (<>      

                                    {(!!bookData.StartTime) && (
                                        <div className="form-group row">                                    
                                            <label className="col-form-label col-lg-3"> Start Time </label>
                                            <div className="col-xl-6 col-lg-8">
                                                <input 
                                                    id="lblStartTime" name="lblStartTime" type="text" className="form-control" disabled="disabled" 
                                                    value={bookData?.StartTime ||''} 
                                                />
                                            </div>
                                        </div>
                                    )}                          
                                    <div className="form-group row">                                    
                                    <label className="col-form-label col-lg-3"> Hole No. </label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="lblHoleNo" name="lblHoleNo" type="text" className="form-control" disabled="disabled" 
                                                value={bookData?.HoleNumber ||''} 
                                            />
                                        </div>
                                    </div>
                                </>):(<>                                   
                                    <div className="form-group row">                                    
                                        <label className="col-form-label col-lg-3"> Tee Box </label>
                                        <div className="col-xl-6 col-lg-8">
                                            <select className="form-control" 
                                                onChange={({target}) => onTeeBoxChange(target.value)}
                                                value={bookData.TeeBox || 0}
                                            >
                                                <option value={0} disabled> Select TeeBox </option>
                                                <option value={1} > 1 </option>
                                                <option value={10} > 10 </option>                                            
                                        </select>
                                        </div>
                                    </div>
                                    {bookData?.TeeBox && (
                                        <div id="pnlEdit" className="form-group row">
                                            <label className="col-form-label col-lg-3"> Available Slot <span className="red">*</span></label>
                                            <div className="col-xl-6 col-lg-8"> 
                                                <input 
                                                    id="lblSlot" name="lblSlot" type="text" className="form-control" disabled="disabled" 
                                                    value={bookData?.Slot || ''} 
                                                />                                                                                
                                            </div>
                                        </div>
                                    )}                                
                                </>)}

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3"> Member </label>
                                    <div className="col-8">
                                        <div className="input-group" >
                                            <div style={{width: "50%"}} >
                                                <Select
                                                    id = "ddlUser" name = "ddlUser" className = "ddlUser" placeholder = "Select User" 
                                                    options  = {pageData?.TeeTimeMembers?.map(c=>({label: `${c.FirstName} ${c.LastName} (${c.Mobile})`, value: c }))}
                                                    value    = {selectedUser || ''}                                                    
                                                    onChange = {(event) => setSelectedUser(event)}
                                                />
                                            </div>
                                            <div className="input-group-append" style={{zIndex: 0}}>
                                                <button className="btn btn-secondary kt-select-reset" type="button" onClick={()=> setSelectedUser()}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                            </div>
                                            <button id="btnAddUser" className="btn btn-label-brand margin-l10 margin-r0" type="button" onClick={addBookingUser}> 
                                                <i className="la la-plus" style={{color: '#5d78ff'}}></i>Add 
                                            </button>                                            
                                            <button id="btnScan" className="btn btn-label-success margin-l10 margin-r0" type="button" onClick={()=> {userList.length > 3 ?  (swal.fire({ icon: 'warning', titleText: 'Maximum 4 Players Allowed'})) : setShowScanner(true)}}> 
                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon-scanner" style={{height: '19px'}}>
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <rect fill="#000000" opacity="0.3" x="4" y="4" width="8" height="16"/>
                                                        <path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fillRule="nonzero"/>
                                                    </g>
                                                </svg>
                                                Scan 
                                            </button>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3"> Comment </label>
                                    <div className="col-xl-6 col-lg-8">
                                        <textarea 
                                            id="txtComment" name="txtComment" className="form-control" 
                                            rows="3" value={bookData.Comment || ''}
                                            onChange={(event) => inputHandler('Comment', event.target.value)} 
                                        ></textarea>
                                    </div>
                                </div>
                            </form>
                            {/* PLAYERS */}
                            {(userList.length > 0) &&(
                                <div className="row">
                                    <div className="col-12 padding-lr50">
                                        <table id="table-user" className="table table-bordered table-hover margin-b30">
                                            <thead className="light-grey-background">
                                                <tr><th>Players</th><th className="text-center">Action</th></tr>
                                            </thead>
                                            <tbody>
                                                {userList.map((u, i) => (
                                                    <tr key={i}>
                                                        <td> {`${u.FirstName} ${u.LastName} (${u.MembershipNo})`} </td>
                                                        <td className="text-center"> 
                                                            <button className = "btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete" type = "button"
                                                                onClick={() => deleteUser(u.UserID)}
                                                            >
                                                                <i className = "flaticon2-rubbish-bin-delete-button"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}                                               
                                            </tbody>
                                            <tfoot></tfoot>
                                        </table>
                                    </div>
                                </div>  
                            )}
                            <div className="row mb-4">
                                <div className="col-12 text-center">
                                    <button id="btnBook" className="btn btn-primary d-flex" disabled={(((StartType =='Slot') && !bookData?.TeeBox )|| ((StartType == 'Shotgun') && !bookData?.HoleNumber)) ? 'disabled' : ''} type="button" onClick={()=> postBooking()}>
                                        Book
                                    </button>                                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(!!showScanner) && 
                <ScannerModal
                    show           = {showScanner}
                    onDismissModal = {()=> setShowScanner(false)}
                    setUserList    = {setUserList}
                    userList       = {userList}
                    holiday        = {holiday}
                />
            }                                           
        </>
    )
}

export default TeeBooking;