import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import { getCourseAll, getTeeBoxAll, postTeeBox } from "../api/golfClubAPI";

const TeeBox = (props) =>{

    const [teeBox,                setTeeBox]                = useState({Gender: "Male"});
    const [teeBoxList,            setTeeBoxList]            = useState([]);
    const [isInit,                setIsInit]                = useState(true);
    const [courseList,            setCourseList]            = useState([]);
    const [rightSideDetails,      setRightSideDetails]      = useState(false);

    useEffect(() => {
        props.title("Tee Box", "teebox");
        fetchTeeBox();
        fetchCourse();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setTeeBox({Gender: "Male"});
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [teeBoxList]);

    const fetchTeeBox = async() =>{
        try {
            const teeBoxs = await getTeeBoxAll()   
            isInit ? setIsInit(false) : $('#tblTeeBox').KTDatatable().destroy(); 
            setTeeBoxList(teeBoxs);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchCourse = async() =>{
        try {
            const courses = await getCourseAll() 
            setCourseList(courses);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblTeeBox').KTDatatable({
            data: {
                saveState: false,
                source: teeBoxList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'ShortName',
                    title: 'Course',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="user-profile" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.CourseName} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.ShortName} </span>
                                </div>
                            </div>`
                    }
                   
                },
                {
                    field: 'Name',
                    title: 'Name',
                    textAlign: 'center',
                },
                {
                    field: 'CourseRating',
                    title: 'Course Rating',
                    textAlign: 'center',
                   
                },
                {
                    field: 'Slope',
                    title: 'Slope',
                    width: 50,
                    textAlign: 'center',
                    
                },
                {
                    field: 'Gender',
                    title: 'Gender',
                    textAlign: 'center',
                },
               
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblAdminMenu .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setTeeBox(dataTable.dataSet[index]);  
                setRightSideDetails(true);    
            } 
        });

    }

    const updateTeeBoxDetails = (field, value) => {  
        setTeeBox((d) => {
            return { ...d, [field]: value };
        });
    };

    const addTeeBoxHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-teeBox-form');
        
        form.validate({
            rules: {
                ddlCourse: {
                    required: true
                },
                txtName: {
                    required: true
                },
                txtCourseRating: {
                    required: true,
                    number: true

                },
                txtSlope: {
                    required: true,
                    number: true

                },
                ddlGender: {
                    required: true,
                }           
            },
            messages: {
                ddlCourse: {
                    required: "Please select course"
                },
                txtName: {
                    required: "Please enter name"
                },
                txtCourseRating: {
                    required: "Please enter course rating"
                },
                txtSlope: {
                    required: "Please enter slope"
                },
                ddlGender: {
                    required: "Please select gender"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postTeeBox(teeBox);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchTeeBox();
            if (teeBox.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'TeeBox updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'TeeBox added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Tee Box..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Tee Box</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblTeeBox" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Tee Box Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-teeBox-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Course <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-4">
                                                    <Select
                                                        value={(courseList && courseList.length > 0 && teeBox.CourseID) && {label: courseList.find(c => c.ID == teeBox.CourseID).Name, value: teeBox.CourseID}}
                                                        onChange={(event) => updateTeeBoxDetails('CourseID', event.value)}
                                                        name="ddlCourse"
                                                        placeholder="Select Course"
                                                        id="ddlCourse"
                                                        className="ddlCourse"
                                                        isDisabled={teeBox.ID}
                                                        options={courseList.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-xl-4 col-lg-6">
                                                    <input 
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={teeBox.Name || ''}
                                                        onChange={(event) => updateTeeBoxDetails('Name', event.target.value)}  
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Course Rating <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-6">
                                                    <input 
                                                        id="txtCourseRating" 
                                                        name="txtCourseRating" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={teeBox.CourseRating || ''}
                                                        onChange={(event) => updateTeeBoxDetails('CourseRating', event.target.value)}
                                                     />
                                                </div>
                                            </div>
                                                <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Slope <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-6">
                                                    <input 
                                                        id="txtSlope" 
                                                        name="txtSlope" 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={teeBox.Slope || ''}
                                                        onChange={(event) => updateTeeBoxDetails('Slope', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Gender <span className="red">*</span></label>
                                                <div className="col-xl-2 col-lg-2">
                                                    <div className="input-group">
                                                        <select 
                                                            id="ddlGender" 
                                                            name="ddlGender"
                                                            className="form-control kt-selectpicker show-tick"
                                                            value={teeBox.Gender || ''}
                                                            onChange={(event) => updateTeeBoxDetails('Gender', event.target.value)}
                                                            disabled = {teeBox.ID}
                                                        >
                                                            <option value="Male" >Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                    <div className="row">
                                                        <div className="col-lg-9 ml-lg-auto">
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addTeeBoxHandler}>Save</button>
                                                            <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>           
            </div>
        </>
    )
}

export default TeeBox;