import { useEffect, useState } from "react";
import './scanner.css';
import { getUserByRFID } from "../api/offlineTeeAPI";
import swal from 'sweetalert2';


const ScannerModal = (props) => {

    const [rfid,                setRfid]              = useState(null);
    const [userDetails,         setUserDetails]       = useState(null);

    useEffect(()=>{
      $("#scannerModal").modal({ backdrop: "static" });      
      $("#scannerModal").on("hidden.bs.modal",()=> props.onDismissModal() );
      $("#scannerModal").modal("toggle");
      setTimeout(() => {
        $("#rfidInput").focus();
      }, 500);
    },[])

    useEffect(() => {
      if(rfid && rfid.length == 10){
        fetchUserByRFID(rfid);
      }
    }, [rfid]);

    const focusRFID = (e) => {
      e.preventDefault()
      $("#rfidInput").focus();
    }

    const inputHandler = (value)=> setRfid(value);

      const fetchUserByRFID = async(data) => {      
        try {          
            const user = await getUserByRFID(data)  
            if(user.Message == 'User not Found'){
              swal.fire({
                icon: 'warning',
                titleText: 'User not Found',
                showConfirmButton: false,
                timer: 1500,
              });
              setRfid(null)
            }else{
              setUserDetails(user);
              setRfid(null)
            }

        } catch (err) {
            swal.close();
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const onSave = () => {
      const isExist = props.userList.find( u => u.UserID == userDetails.ID);
      if(isExist) return swal.fire({ icon: 'warning', titleText: 'Member Already Added' });
      
      if(userDetails.MembershipCategory == 'Mid-Week Member' && props.holiday) return swal.fire({icon: 'warning',titleText: 'Booking not available for Mid-Week Members', showConfirmButton: true,});
    
      props.setUserList(d => ([...d, { UserID: userDetails.ID, UserType: 'User', FirstName: userDetails.FirstName, MiddleName: userDetails.MiddleName, LastName: userDetails.LastName, MembershipNo : userDetails.MembershipNo }]));   
      $('#scannerModal').modal('hide');
      swal.fire({
        icon: 'success',
        titleText: 'User added successfully!',
        showConfirmButton: false,
        timer: 1500,
      });

    }

    return(<>
        <div className="modal fade" id="scannerModal" tabIndex={-1} role="dialog"  onClick={focusRFID}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title"> Scan </h5>
                    {!userDetails && <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>}
                  </div>
                  {!!userDetails ? (
                    <>
                      <div className="modal-body margin-l150 margin-r150">
                        <div >
                            <div className="kt-portlet kt-portlet--height-fluid-"  style={{boxShadow: '0px 0px 13px 0px rgb(82 63 105 / 31%)'}}>
                              <div className="kt-portlet__body kt-portlet__body--fit-y  padding-lr30 padding-t40">
                                <div className="kt-widget kt-widget--user-profile-1">
                                  <div className="kt-widget__head">
                                    <div className="kt-widget__media">
                                      <div className="kt-user-card-v2__pic">
                                        {userDetails.Photo && userDetails.Photo != '' ? 
                                          <img src={userDetails.Photo} alt="user-profile" /> : 
                                          <div className={`kt-badge kt-badge--xl kt-badge--${userDetails.Category === 'Organization' ? 'success' : 'info'}`}  style={{width: '70px', height: '80px', fontSize: '2.1rem', borderRadius: '8px' }}>
                                            {userDetails.FirstName && userDetails.FirstName.slice(0, 1)}
                                          </div>
                                        } 
                                      </div>
                                    </div>
                                    <div className="kt-widget__content">
                                      <div className="kt-widget__section margin-t5">
                                        <a href="#" className={`kt-widget__username '}`} style={{fontSize: '1.6rem'}}>
                                          {`${userDetails.FirstName} ${userDetails.LastName}`}
                                        </a>
                                        <div style={{display : 'flex'}}>
                                          <span className={`kt-widget__subtitle ${userDetails.Category === 'Organization' ? 'padding-r170' : 'padding-r200'}`}>
                                            {userDetails.MembershipNo}
                                          </span>
                                          <span className={`kt-badge kt-badge--${userDetails.Category === 'Organization' ? 'success' : 'info'} kt-badge--inline kt-badge--pill padding-lr10`}>{userDetails.Category}</span>

                                        </div>
                                        <span className="kt-widget__subtitle">
                                          {userDetails.MembershipCategory}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kt-widget__body">
                                    <div className="kt-widget__content">
                                      <div className="kt-widget__info">
                                        <span className="kt-widget__label padding-l10">Email:</span>
                                        <a href="#" className="kt-widget__data padding-r10">{userDetails.Email}</a>
                                      </div>
                                      <div className="kt-widget__info">
                                        <span className="kt-widget__label padding-l10">Phone:</span>
                                        <a href="#" className="kt-widget__data padding-r10">{userDetails.Mobile}</a>
                                      </div>
                                      <div className="kt-widget__info">
                                        <span className="kt-widget__label padding-l10">MemberType:</span>
                                        <span className="kt-widget__data padding-r10">{userDetails.MemberType}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
									          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal"> Close </button>
                        <button id="save-btn" type="button" className="btn btn-success margin-0" onClick={onSave}> Add </button> 
                      </div>
                    </>
                    
                  ):(
                    <div className="modal-body margin-b20">
                        <input 
                            id="rfidInput" name="rfidInput" style={{color: '#ffffff',backgroundColor: '#fff',border: '#fff', height: '2px'}}  autoComplete="off" className="form-control"
                            rows="3"
                            value={rfid || ''}
                            onChange={(event) => inputHandler(event.target.value)} 
                        ></input>
                      <div className="cardBody" >
                        <div className="container">
                          <div className="borders">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                          <p>
                            <span></span>
                            Scanning
                          </p>
                          <div className="box">
                            <div className="pic"></div>
                            <div className="content">
                              <span style={{'--i': '1'}}></span>
                              <span style={{'--i': '2'}}></span>
                              <span style={{'--i': '3'}}></span>
                              <span style={{'--i': '4'}}></span>
                              <span style={{'--i': '5'}}></span>
                              <span style={{'--i': '6'}}></span>
                              <span style={{'--i': '7'}}></span>
                              <span style={{'--i': '8'}}></span>
                              
                            </div>
                          </div>
                          <div className="scanner"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
            </div>
        </div>
    </>)
}

export default ScannerModal;