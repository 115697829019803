import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";


const getViewUserAllUrl                                  = '/api/admin/users/getViewUserAll';
const addUserUrl                                         = '/api/admin/users/addUser';
const verifyMembershipNoUrl                              = '/api/admin/users/verifyMembershipNo';
const verifyMobileNoUrl                                  = '/api/admin/users/verifyMobileNo';
const updateMemberGroupUrl                               = '/api/admin/users/updateMemberGroup';
const postMemberUrl                                      = '/api/admin/users/postMember';


export const getViewUserAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getViewUserAllUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};


export const addUser = async (userList, memberGroup) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        addUserUrl,
      {  userList, memberGroup },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
  };


export const verifyMembershipNo = async (membershipNo) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
      verifyMembershipNoUrl,
      {  membershipNo },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
  };

export const verifyMobileNo = async (mobileNo) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
      verifyMobileNoUrl,
      {  mobileNo },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateMemberGroup = async (memberDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    updateMemberGroupUrl,
    {  memberDetails },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postMember = async (user) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    postMemberUrl,
    {  user },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};