import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete } from "./axiosFunctions";


//#region Api URL's --> 

const getOfflineStaggeredMasterAllUrl           = '/api/admin/offlineTee/getOfflineStaggeredMasterAll';
const postOfflineStaggeredMasterUrl             = '/api/admin/offlineTee/postOfflineStaggeredMaster';

const getOfflineStaggeredBlockAllUrl            = '/api/admin/offlineTee/getOfflineStaggeredBlockAll';
const postOfflineStaggeredBlockUrl              = '/api/admin/offlineTee/postOfflineStaggeredBlock';

const getOfflineStaggeredTemplateDataUrl        = '/api/admin/offlineTee/getOfflineStaggeredTemplateData';
const postOfflineStaggeredTemplateUrl           = '/api/admin/offlineTee/postOfflineStaggeredTemplate';
const deleteOfflineStaggeredTemplateUrl         = '/api/admin/offlineTee/deleteOfflineStaggeredTemplate';

const getOfflineShotgunMasterAllUrl             = '/api/admin/offlineTee/getOfflineShotgunMasterAll';
const postOfflineShotgunMasterUrl               = '/api/admin/offlineTee/postOfflineShotgunMaster';

const getOfflineShotgunBlockAllUrl              = '/api/admin/offlineTee/getOfflineShotgunBlockAll';
const postOfflineShotgunBlockUrl                = '/api/admin/offlineTee/postOfflineShotgunBlock';

const getOfflineShotgunTemplateDataUrl          = '/api/admin/offlineTee/getOfflineShotgunTemplateData';
const postOfflineShotgunTemplateUrl             = '/api/admin/offlineTee/postOfflineShotgunTemplate';
const deleteOfflineShotgunTemplateUrl           = '/api/admin/offlineTee/deleteOfflineShotgunTemplate';

const getOfflineShotgunCalendarAllUrl           = '/api/admin/offlineTee/getOfflineShotgunCalendarData';   
const postOfflineShotgunCalendarUrl             = '/api/admin/offlineTee/postOfflineShotgunCalendar';  
const deleteOfflineShotgunCalendarUrl           = '/api/admin/offlineTee/deleteOfflineShotgunCalendar';  

const getOfflineShotgunMappingByCalendarIDUrl   = '/api/admin/offlineTee/getOfflineShotgunMappingByCalendarID';

const getOfflinePublishPageDataUrl              = '/api/admin/offlineTee/getOfflinePublishPageData';    
const getOfflinePublishMasterDataUrl            = '/api/admin/offlineTee/getOfflinePublishMasterData';    
const postOfflineTeeTimePublishUrl              = '/api/admin/offlineTee/postOfflineTeeTimePublish';    

const getLatestOfflineTeeTimeTemplateUrl        = '/api/admin/offlineTee/getLatestOfflineTeeTimeTemplate'; 
const getOfflineTeeTimeBookDataUrl              = '/api/admin/offlineTee/getOfflineTeeTimeBookData'; 

const getOfflineTeeTimeBookSheetUrl             = '/api/admin/offlineTee/getOfflineTeeTimeBookSheet'; 

const postOfflineBookingUrl                     = '/api/admin/offlineTee/postOfflineBooking'; 
const updateOfflineBookingUrl                   = '/api/admin/offlineTee/updateOfflineBooking'; 
const cancelOfflineBookingUrl                   = '/api/admin/offlineTee/cancelOfflineBooking'; 
const getOfflineCancellationSheetUrl            = (fDate, tDate) => `/api/admin/offlineTee/getOfflineCancellationSheet/${fDate}/${tDate}`;
const getUserByRFIDUrl                          = (data) => `/api/admin/offlineTee/getUserByRFID/${data}`; 
const verifyOfflineDateUrl                      = '/api/admin/offlineTee/verifyOfflineDate';  
const getOfflineShotgunBookForBlockingUrl       = '/api/admin/offlineTee/getOfflineShotgunBookForBlocking';  
const getOfflineTeeTimeBookForBlockingUrl       = '/api/admin/offlineTee/getOfflineTeeTimeBookForBlocking'; 
const deleteOfflineTeeTimeBlockUrl              = (id) => `/api/admin/offlineTee/deleteOfflineTeeTimeBlock/${id}`
const postOfflineTeeTimeBlockUrl                = '/api/admin/offlineTee/postOfflineTeeTimeBlock';  


//#endregion


export const getOfflineStaggeredMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflineStaggeredMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineStaggeredMaster= async(teeMaster)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineStaggeredMasterUrl, { teeMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getOfflineStaggeredBlockAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflineStaggeredBlockAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineStaggeredBlock= async(teeBlocks)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineStaggeredBlockUrl, { teeBlocks }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getOfflineStaggeredTemplateData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflineStaggeredTemplateDataUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineStaggeredTemplate= async( teeTemplates )=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineStaggeredTemplateUrl, { teeTemplates }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteOfflineStaggeredTemplate = async(templateId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosDelete( `${deleteOfflineStaggeredTemplateUrl}/${templateId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}


export const getOfflineShotgunMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflineShotgunMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineShotgunMaster = async(shotgunMaster) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineShotgunMasterUrl, { shotgunMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getOfflineShotgunBlockAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflineShotgunBlockAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineShotgunBlock= async(shotgunBlocks)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineShotgunBlockUrl, { shotgunBlocks }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getOfflineShotgunTemplateData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflineShotgunTemplateDataUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineShotgunTemplate= async( shotgunTemplates )=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineShotgunTemplateUrl, { shotgunTemplates }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteOfflineShotgunTemplate = async(templateId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosDelete( `${deleteOfflineShotgunTemplateUrl}/${templateId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getOfflineShotgunCalendarData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflineShotgunCalendarAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineShotgunCalendar = async(calendar, blocks) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineShotgunCalendarUrl, { calendar, blocks }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteOfflineShotgunCalendar = async(calendarId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosDelete( `${deleteOfflineShotgunCalendarUrl}/${calendarId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getOfflineShotgunMappingByCalendar = async(calendarId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getOfflineShotgunMappingByCalendarIDUrl}/${calendarId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getOfflinePublishPageData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getOfflinePublishPageDataUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getOfflinePublishMasterData = async(masterId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getOfflinePublishMasterDataUrl}/${masterId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postOfflineTeeTimePublish = async(data) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineTeeTimePublishUrl, {...data }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getLatestOfflineTeeTimeTemplate = async(teebox) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getLatestOfflineTeeTimeTemplateUrl}/${teebox}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getOfflineTeeTimeBookData = async() => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( getOfflineTeeTimeBookDataUrl, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getOfflineTeeTimeBookSheet = async(date, display) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getOfflineTeeTimeBookSheetUrl}/${date}/${display}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const postOfflineBooking = async(book, users)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineBookingUrl, { book, users }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const updateOfflineBooking = async(book, users)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( updateOfflineBookingUrl, { book, users }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const cancelOfflineBooking = async(bookId, comment)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( cancelOfflineBookingUrl, { headers: { auth_token: `bearer ${token}` }, params: { bookId, comment }  } );
    return response;
}

export const getOfflineCancellationSheet = async (fDate, tDate) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getOfflineCancellationSheetUrl(fDate, tDate),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getUserByRFID = async(data) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( getUserByRFIDUrl(data), { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const verifyOfflineDate = async(date) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${verifyOfflineDateUrl}/${date}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getOfflineShotgunBookForBlocking = async(date, blockId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getOfflineShotgunBookForBlockingUrl}/${date}/${blockId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getOfflineTeeTimeBookForBlocking = async(date, teeBox) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getOfflineTeeTimeBookForBlockingUrl}/${date}/${teeBox}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const deleteOfflineTeeTimeBlock = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteOfflineTeeTimeBlockUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postOfflineTeeTimeBlock = async(books, date) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postOfflineTeeTimeBlockUrl, { books, date }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}
