// import { useState, useEffect } from "react";
// import swal from 'sweetalert2';


const dayList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const sortDays = (date) => {
    const currentDay = moment(date).format('ddd');  //dddd for long format

    const dayIndex  = dayList.indexOf(currentDay);

    const beforeDays = dayList.slice(0, dayIndex);
    const AfterDays  = dayList.slice(dayIndex, dayList.length);

    return [...AfterDays, ...beforeDays];
}

const TeeTimePublishTable = (props) => {
    const { publishMaster, dayData } = props;

    const generateMainTable = ()=> {
        const { StartDate, EndDate, SkipDays } = publishMaster;
        
        if((publishMaster?.ID) && (!!dayData)){
            const {publishWeek, publishDays} = dayData;                         
            return(<>
                {!!publishWeek && (
                    <table id="tblDayMaster" className="table table-bordered table-hover margin-b30 ">
                         <thead className="thin-border-bottom">
                            <tr>
                                <th></th>
                                <th>Morning</th>
                                <th>Afternoon</th>
                            </tr>
                        </thead>
                        <tbody>
                            {publishWeek.map( (weekDay, i) => {
                                return(
                                    <tr key={i} data-rowindex={weekDay.Index}>
                                        <th width="8%" data-day={weekDay.Day}> {weekDay.Day} </th>
                                        <td width="10%">
                                            <div className="input-group timepicker width-120 mr-2">
                                                <input id={`txtSlot1-${weekDay.Day}`} name="txtSlot" type="text" readOnly={true} 
                                                className="form-control slot-picker" placeholder="Select time" 
                                                value={weekDay.MorningTime || ''}
                                                />                                   
                                            </div>
                                        </td>
                                        <td width="10%">
                                            <div className="input-group timepicker width-120 mr-2">
                                                <input id={`txtSlot2-${weekDay.Day}`} name="txtSlot" type="text" readOnly={true} 
                                                className="form-control slot-picker" placeholder="Select time"
                                                value={weekDay.AfternoonTime || ''} 
                                                />                                   
                                            </div>
                                        </td>
                                        
                                        {(weekDay.DayCount > 0) ? (dayList.map( (t,j) => {    
                                            const assignedDays = publishDays.filter(d => d.PublishWeekID == weekDay.ID);
                                            if(j < assignedDays.length){                                        
                                                return(
                                                    <td className="day-holder" key={j}  width="11%"> 
                                                        <div className="dragable-day" data-val={assignedDays[j].Day} data-index={assignedDays[j].Index} > <span> {assignedDays[j].Day} </span> </div>
                                                    </td>
                                                )
                                            }else{
                                                return (<td className="day-holder" key={j}  width="10%"></td>)
                                            }                                                                            
                                        })):(
                                            <>
                                                <td className="day-holder"  width="10%"></td>
                                                <td className="day-holder"  width="10%"></td>
                                                <td className="day-holder"  width="10%"></td>
                                                <td className="day-holder"  width="10%"></td>
                                                <td className="day-holder"  width="10%"></td>
                                                <td className="day-holder"  width="10%"></td>
                                                <td className="day-holder"  width="10%"></td>
                                            </>
                                        )}                                    
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
           </>)
        }else{          
            const weekDays = sortDays(StartDate);
            return(<>
                 {!!weekDays && (
                    <table id="tblDayMaster" className="table table-bordered table-hover margin-b30 ">
                         <thead className="thin-border-bottom">
                            <tr>
                                <th></th>
                                <th>Morning</th>
                                <th>Afternoon</th>
                            </tr>
                        </thead>
                        <tbody>
                            {weekDays.map( (day, i) => {
                                return(
                                    <tr key={i} data-rowindex={i + 1}>
                                        <th width="8%" data-day={day}> {day} </th>
                                        <td width="10%">
                                            <div className="input-group timepicker width-120 mr-2">
                                                <input id={`txtSlot1-${day}`} name="txtSlot" type="text" readOnly={true} 
                                                    className="form-control slot-picker" placeholder="Select time" 
                                                />                                   
                                            </div>
                                        </td>
                                        <td width="10%">
                                            <div className="input-group timepicker width-120 mr-2">
                                                <input id={`txtSlot2-${day}`} name="txtSlot" type="text" readOnly={true} 
                                                    className="form-control slot-picker" placeholder="Select time" 
                                                />                                   
                                            </div>
                                        </td>
                                        <td className="day-holder"  width="10%"></td>
                                        <td className="day-holder"  width="10%"></td>
                                        <td className="day-holder"  width="10%"></td>
                                        <td className="day-holder"  width="10%"></td>
                                        <td className="day-holder"  width="10%"></td>
                                        <td className="day-holder"  width="10%"></td>
                                        <td className="day-holder"  width="10%"></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}        
            </>)
        }
    }

    const generateSideTable = ()=> {
        const { StartDate, EndDate, SkipDays } = publishMaster;

        if( (publishMaster?.ID) && (!!dayData)){          
            return(
                <table id="table-days" className="table table-bordered table-register height-210">
                    <thead className="light-grey-background">
                        <tr><th width="10%">Days </th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td id="days-holder"> </td>
                        </tr>
                    </tbody>
                </table>
            ) 
        }else{                        
            const targetDate = moment(StartDate).add(SkipDays, 'days');
            const dragDays   = sortDays(targetDate);
                  
            return(<>
                {!!dragDays &&(
                    <table id="table-days" className="table table-bordered table-register height-210">
                        <thead className="light-grey-background">
                            <tr><th width="10%">Days </th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="days-holder">
                                    {dragDays.map( (day,i) => {
                                        return( <div key={i} className="dragable-day" data-val={day} data-index={i+1}> <span> {day} </span> </div> )
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}    
            </>)
        }
    }
   
    return(<>
        {publishMaster && (
            <div id="pnlDayMaster" className="row prevent-select ">
                <div className="col-11">
                    {generateMainTable()}
                </div>
                <div className="col-1 pl-2">
                    {generateSideTable()}
                </div>
            </div>  
        )}
    </>)
}


export default TeeTimePublishTable;